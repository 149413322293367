import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    useGetChatQuery, 
    useGetChatListQuery, 
    useGetChatModesQuery,
    useCreateChatMutation,
    useDeleteChatMutation,
    useUpdateChatMutation,
    useSendMessageMutation,
    useSendWebSocketMessageMutation,
} from '../../utils/api/aiChatApi'; 
import { useGetProjectsQuery } from '../../utils/api/audioApi'; 
import { Row, Col, List, Typography, Button, Avatar, Space, Input, message, Popconfirm, Divider, Popover, Select, Tooltip, Modal, Drawer, Slider } from 'antd';
import { SettingOutlined, PlusCircleOutlined, SendOutlined, DeleteOutlined, SaveOutlined, InfoCircleOutlined, MessageOutlined, ToolOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useTranslation } from 'react-i18next';

import './AiChat.css';
import { use } from 'i18next';
import TextArea from 'antd/es/input/TextArea';

const { Text, Title } = Typography;
const { Option } = Select;

function NewlineText({ text }) {
    const newText = text.replace("\n\n", "\n").split("\n").map((str, index, array) => 
      index === array.length - 1 ? str : <Text key={index}>
        {str}
        <br />
      </Text>
    );
  
    return <>{newText}</>;
  }


const IconText = ({ icon, text, onClick, color }) => (
    <Space style={{cursor: 'pointer', color: color}} onClick={onClick}>
        {React.createElement(icon)}
        {text}
    </Space>
);

const languages = {
    'en': 'English',
    'ua': 'Ukrainian',
    'ro': 'Romanian',
}

const AiChat = () => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const [chatUuid, setChatUuid] = useState();
    const [chatId, setChatId] = useState();
    const [chatName, setChatName] = useState(t("New ai chat"));
    const [messageText, setMessageText] = useState();
    const [messageId, setMessageId] = useState();
    const [projectId, setProjectId] = useState();
    const lastMessageRef = useRef(null);
    const chatContainerRef = useRef(null);
    const textareaRef = useRef(null);
    const [answerContext, setAnswerContext] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newChatConfirmOpen, setNewChatConfirmOpen] = useState(false);
    const [chatMode, setChatMode] = useState(1);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [answerSystemContext, setAnswerSystemContext] = useState();
    const [answerTemperature, setAnswerTemperature] = useState(0);
    
    const { data: projects, isFetching: isFetchingProjects, isSuccess: isSuccessProjects, refetch: refetchProjects } = useGetProjectsQuery();
    const { data: chatModes, isFetching: isFetchingChatModes, isSuccess: isSuccessChatModes, refetch: refetchChatModes } = useGetChatModesQuery();

    const { data: chatList, error: chatListError, isLoading: chatListIsLoading, refetch: refetchChatList } = useGetChatListQuery();
    const { data: chat, error: chatError, isLoading: chatIsLoading, isFetching: isFetchingChat, isSuccess: isSuccessChat, refetch: refetchChat } = useGetChatQuery(chatUuid, {skip: chatUuid === undefined || chatUuid === null ? true : false});
  
    const [sendMessage, { isLoading: sendIsLoading }] = useSendMessageMutation();
    const [sendWebSocketMessage, { isLoading: sendWebSocketIsLoading }] = useSendWebSocketMessageMutation();
    const [createChat, { isLoading: createChatIsLoading }] = useCreateChatMutation();
    const [updateChat, { isLoading: updateChatIsLoadint }] = useUpdateChatMutation();
    const [deleteChat, { isLoading: deleteChatIsLoading }] = useDeleteChatMutation();

    const sendAiChatMessage = async (message, chatId) => {
        if (!message) return;
        try {
            sendWebSocketMessage({type: "chat.message.create", message: message, chat_id: chatId, language: languages[i18n.language]}).then(() => {
                setMessageText('');
            });
        } catch (err) {
            console.error('Failed to send message:', err);
        }
    };

    const createAiChat = async ({name, project}) => {
        if (!name) return;
        try {
            await createChat({name, project, mode:chatMode}).then((res) => {
                setChatUuid(res?.data?.chat?.uuid);
                setChatId(res?.data?.chat?.id);
                refetchChatList();
            });
        } catch (err) {
            console.error('Failed to create chat:', err);
        }
    };

    const deleteAiChat = async (uuid) => {
        if (!uuid) return;
        try {
            await deleteChat({uuid}).then((res) => {
                refetchChatList();
                setChatUuid(null);
                setChatId(null);
                
            });
        } catch (err) {
            console.error('Failed to create chat:', err);
        }
    };

    const updateAiChat = async (uuid) => {
        if (!uuid) return;
        try {
            await updateChat({uuid, answerSystemContext, answerTemperature:answerTemperature/100}).then((res) => {
                console.log('updated');
            });
        } catch (err) {
            console.error('Failed to update chat:', err);
        }
    };

    const handleNewChatConfirmOpenChange = (newOpen) => {
        setNewChatConfirmOpen(newOpen);
    }

    const handleNewChatConfirmClose = () => {
        setNewChatConfirmOpen(false);
    }

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            const element = chatContainerRef.current;
            element.scrollTop = element.scrollHeight;
        }
    };


    useEffect(() => {
        scrollToBottom();
    }, [chat?.messages]);

    useEffect(() => {
        if (chatList?.length > 0) {
            textareaRef.current.focus();
        }
    }, [chatList]);

    useEffect(() => {
        setIsDrawerVisible(false);
    }, [chatUuid]);

    useEffect(() => {
        if(chat && !chatIsLoading && !isFetchingChat && isSuccessChat) {
            setAnswerSystemContext(chat?.chat?.answer_system_context);
            setAnswerTemperature(chat?.chat?.answer_temperature*100);
        }
    }, [chat, chatIsLoading, isFetchingChat, isSuccessChat]);

    const newChatConfirm = () => {
        return (
            <>
                <Space direction="vertical" size='small' style={{minWidth:'100%'}}>
                <Input
                    onChange={(e) => {setChatName(e.target.value)}}
                    value={chatName}
                    placeholder={t("Chat name")}
                    style={{ minWidth: 300 }}
                    allowClear={true}
                />
                <Select 
                    allowClear
                    showSearch
                    filterOption={(input, option) => { return ((`${option?.value} ${option?.label}`) ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}}
                    value={projectId}
                    onChange={(a,e)=>{setProjectId(e?.value)}}
                    placeholder={t("Project")}
                    options={projects.filter(obj => !obj.disabled)?.map((p) => (
                        { value: p?.id, label: `${p?.projid}: ${p?.project}`, disabled: p.disabled }
                    ))}
                    loading={isFetchingProjects}
                    style={{ minWidth: 300 }}
                />
                <Select 
                    allowClear
                    showSearch
                    filterOption={(input, option) => { return ((`${option?.value} ${option?.label}`) ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}}
                    value={chatMode}
                    onChange={(a,e)=>{setChatMode(e?.value)}}
                    placeholder={t("Chat Mode")}
                    loading={isFetchingChatModes}
                    style={{ minWidth: 300 }}
                >
                    {
                        chatModes?.map((p) => (
                            <Option value={p?.id}>
                                <Space direction='horizontal'>
                                    <Text>{p?.name}</Text>
                                    <Tooltip 
                                            title={p?.description}
                                            placement="right"
                                            
                                            >
                                        <InfoCircleOutlined style={{cursor: 'help'}} />
                                    </Tooltip>
                                </Space>
                            </Option>
                        ))
                    }

                </Select>
                <Row gutter={24}>
                    <Col span={12}>
                    <Button
                        type="primary"
                        danger
                        className="add-chat-button"
                        icon={<DeleteOutlined />}
                        onClick={() => {handleNewChatConfirmClose()}}
                    >
                        {t("Cancel")}
                    </Button>
                    </Col>
                    <Col span={12}>
                    <Button 
                        disabled={!projectId}
                        type="primary"
                        className="add-chat-button" 
                        icon={<SaveOutlined />} 
                        onClick={() => {
                                createAiChat({name: chatName, project: projectId});
                                handleNewChatConfirmClose();
                            }}
                    >
                        {t("Save")}
                    </Button>
                    </Col>
                </Row>
                </Space>
            </>
        )
    }

    return (
        <Row gutter={24} className="aichat-main-content-row">
            <Col span={5}>
                <Space direction="vertical" size='small' style={{minWidth:'100%'}}>
                    <Space direction="horizontal" size="large" style={{minWidth:'100%', justifyContent: 'center'}}>
                        <Title level={3}>{t("AI Chat")}</Title>
                        {/* <Button type="ghost" className="settings-button" icon={<SettingOutlined />} onClick={() => {console.log("settings")}}></Button> */}
                    </Space>
                    <Popover 
                        placement='bottom'
                        content={newChatConfirm} 
                        title={t("Create new chat?")} 
                        trigger="click" 
                        arrow={false} 
                        open={newChatConfirmOpen}
                        onOpenChange={handleNewChatConfirmOpenChange}
                        >
                        <Button 
                            className="add-chat-button" 
                            icon={<PlusCircleOutlined />} 
                            >
                                {t("Add new chat")}
                        </Button>
                    </Popover>
                <div className="chat-messages-scrollable-div">
                    <List
                        size='small'
                        loading={chatListIsLoading}
                        dataSource={chatList}
                        renderItem={item => (
                            <List.Item
                                key={item.id}
                                actions={[
                                    <Space direction='vertical'>
                                    <Popover
                                        placement='right'
                                        title={t("Chat mode")}
                                        trigger={['hover', 'click']}
                                        content={
                                            item.mode.name
                                        }
                                    >
                                        <Button icon={<InfoCircleOutlined />}></Button>
                                    </Popover>
                                    <Popconfirm
                                        title={t("Delete this chat?")}
                                        // description="Are you sure to delete this task?"
                                        onConfirm={() => {deleteAiChat(item.uuid)}}
                                        // onCancel={cancel}
                                        okText={t("Yes")}
                                        cancelText={t("No")}
                                        okButtonProps={{
                                            loading: deleteChatIsLoading,
                                          }}
                                    >
                                        <Button disabled={deleteChatIsLoading} icon={<DeleteOutlined />}></Button>
                                    </Popconfirm>
                                    </Space>,
                                ]}
                                onClick={() => {
                                    setChatUuid(item.uuid);
                                    setChatId(item.id);
                                    textareaRef.current.focus();
                                }}
                                style={{cursor: 'pointer', padding: '10px'}}
                                className={chatId === item.id ? 'active-chat-list-item' : ''}
                            >
                                <List.Item.Meta
                                    title={item.name}
                                    description={
                                    <Space direction="horizontal">
                                        <Text >
                                            {item?.project?.projid}: {item?.project?.project}
                                        </Text>
                                        <Text type='secondary' style={{fontSize: '10px'}}>
                                            {dayjs(item?.created_at).format('DD.MM.YYYY HH:mm:ss')}
                                        </Text>
                                    </Space>
                                }
                                />
                            </List.Item>
                        )}
                    />
                </div>
                </Space>
            </Col>
            <Col span={1} style={{paddingTop: '12px'}}>
                <Divider type="vertical" style={{height: '100%'}} />
            </Col>
            <Col span={18}>
                <div className="chat-messages-scrollable-div" ref={chatContainerRef}>
                    <List
                        size='small'
                        itemLayout='vertical'
                        loading={{spinning: isFetchingChat, 

                            size: 'large',
                        }}
                        style={{}}
                        dataSource={!isFetchingChat && !chatIsLoading && chatUuid ? chat?.messages : []}
                        renderItem={(item, index) => (
                            <List.Item 
                                className="chat-message-item"
                                actions={[
                                    item?.answer_context &&
                                    <Space size={24}>
                                        <IconText 
                                            icon={InfoCircleOutlined} 
                                            onClick={() => {
                                                setIsModalVisible(true);
                                                setAnswerContext(item?.answer_context);
                                            }}
                                        />
                                        {/* <IconText 
                                            color={item?.comments?.length>0 ? 'blue' : null}
                                            icon={MessageOutlined} 
                                            text={item?.comments?.length>0 ? item?.comments?.length : null}
                                            onClick={(e) => {
                                                setMessageId(item.id);
                                            }}
                                        /> */}
                                        <IconText 
                                            color={item?.answer_temperature ? 'blue' : null}
                                            icon={ToolOutlined} 
                                            text={item?.answer_temperature ? `t=${item?.answer_temperature*100}%` : null}
                                            
                                        />
                                        
                                    </Space>
                                        ]}
                                    

                                >
                                <div ref={index === chat?.messages.length - 1 ? lastMessageRef : null} />
                                <List.Item.Meta
                                    style={{margin: '0px'}}
                                    avatar={<Avatar style={{backgroundColor: item?.created_by?.id === 30006 ? '#4c634c' : 'grey'}}>{item?.created_by?.username[0]}</Avatar>}
                                    title={<Text style={{color: item?.created_by?.id === 30006 ? 'green' : 'black'}}>{item?.created_by?.username}</Text>}
                                    description={<Text style={{fontSize: '10px'}} type='secondary'>{dayjs(item?.created_at).format('DD.MM.YYYY HH:mm:ss')}</Text>}
                                />
                                <Text><NewlineText text={item?.message} /></Text>
                            </List.Item>
                        )}
                    />
                    <Drawer 
                        title={t("Answer settings")}
                        placement="bottom"
                        open={isDrawerVisible}
                        onClose={() => {setIsDrawerVisible(false)}}
                        getContainer={false}
                        closable={false}
                        size='large'
                    >
                        <Space direction="vertical" size='small' style={{minWidth:'100%'}}>
                            <Title level={5}>{t("System context")}</Title>
                            <TextArea
                                onChange={(e) => {setAnswerSystemContext(e.target.value)}}
                                value={answerSystemContext}
                                placeholder={t("System context")}
                                style={{ minWidth: 300 }}
                                allowClear={true}
                                autoSize={{ minRows: 8, maxRows: 12 }}
                            />
                            <Title level={5}>{t("Temperature")}</Title>
                            <Slider 
                                onChange={(value)=>setAnswerTemperature(value)}
                                value={answerTemperature}
                                // defaultValue={answerTemperature}
                                tooltip={{
                                    formatter:(value) => `${value}%`,
                                    }}
                                marks={{0: '0%',12.5: '12.5%',25: '25%',37.5: '37.5%',50: '50%',62.5: '62.5%',75: '75%',87.5: '87.5%',100: '100%'}}
                                />
                            <Button type='primary' onClick={() => {
                                updateAiChat(chatUuid, answerSystemContext, answerTemperature);
                                setIsDrawerVisible(false);
                                }}>
                                    {t("Save")}
                            </Button>
                        </Space>
                    </Drawer>

                </div>
                <Divider  />
                <Row gutter={24} style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Col span={17} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Input.TextArea
                            ref={textareaRef}
                            disabled={chatUuid === undefined || chatUuid === null}
                            placeholder={t("Type your message here...")}
                            allowClear={true}
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            onChange={(e) => setMessageText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.ctrlKey && !e.metaKey && !e.shiftKey && !e.altKey) {
                                    e.preventDefault();
                                    sendAiChatMessage(messageText, chatId)
                                }
                            }}
                            value={messageText}
                        />
                    </Col>
                    <Col span={5}>
                        <Button 
                            size='large'
                            style={{ height: '100%' }}
                            disabled={chatUuid === undefined || chatUuid === null}
                            type="primary"
                            icon={<SendOutlined />}
                            loading={sendIsLoading} 
                            onClick={() => sendAiChatMessage(messageText, chatId )}>
                                {t("Send message")}
                        </Button>
                    </Col>
                    <Col span={2}>
                        <Button 
                            size='large'
                            style={{ height: '100%' }}
                            disabled={chatUuid === undefined || chatUuid === null}
                            type="ghost"
                            icon={<SettingOutlined />}
                            // loading={sendIsLoading} 
                            onClick={() => setIsDrawerVisible(true)}
                            >
                                {/* {t("Send message")} */}
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Modal
                closable={false}
                open={isModalVisible}
                onCancel={() => {setIsModalVisible(false)}}
                footer={null}
                width={"90%"}
                styles={{ minHeight: '90vh', maxHeight: '90vh', overflowY: 'scroll', overflowX: "hidden" }}
                style={{ top: "20px" }}
                mask={false}
            >
                
                <JsonView data={answerContext} shouldInitiallyExpand={allExpanded} style={defaultStyles} />
                
            </Modal>
            
        </Row>

        
    )
}

export default AiChat