import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter } from '@reduxjs/toolkit';


const BASE_URL = process.env.REACT_APP_API_BASE_URL
const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL


const statsAdapter = createEntityAdapter({
  selectId: (item) => item?.id,
});


class WebSocketManager {
  constructor() {
    this.handlers = [];
    this.ws = null;
    this.reconnectDelay = 5000;
    this.url = null;
  }

  connect(url) {
    if (!this.ws) {
      this.url = url;
      this.ws = new WebSocket(this.url);
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event?.data);
        this.handlers.forEach(handler => handler(data));
      };
      this.ws.onclose = (event) => {
        setTimeout(() => this.reconnect(), this.reconnectDelay);
      };
    }
  }

  reconnect() {
    if (this.url) {
      this.close();
      this.connect(this.url);
    }
  }

  registerHandler(handler) {
    this.handlers.push(handler);
  }

  unregisterHandler(handler) {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  sendMessage(message) {
    this.ws?.send(JSON.stringify(message));
  }

  close() {
    this.ws?.close();
    this.ws = null;
  }
}

const wsManager = new WebSocketManager();

function gpuStatsHandler(data, updateCachedData) {
  updateCachedData((draft) => {
    if(data && draft){
      draft.data = data?.data;
    }
  });
}


export const statsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${BASE_URL}`,
    credentials:"include",
  }),
  endpoints: (build) => ({
    getGpuStats: build.query({
      query: () => ({
        url: `/stt/get_gpu_stats`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        wsManager.connect(`${BASE_WS_URL}/statistics/main`);
        wsManager.registerHandler((data) => {
          return gpuStatsHandler(data, updateCachedData);
        });
        try {
          await cacheDataLoaded
        } catch {
          wsManager.unregisterHandler((data) => gpuStatsHandler(data, updateCachedData));
        }
        await cacheEntryRemoved;
        wsManager.unregisterHandler((data) => gpuStatsHandler(data, updateCachedData));
      },
    }),
  }),
  
})


export const { 
  useGetGpuStatsQuery,
} = statsApi
