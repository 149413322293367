import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetTokenMutation } from '../../utils/api/authApi';
import { isLoggedIn } from '../../utils/reducers/authReducer';
import { Form, Input, Button, Spin, Typography, Space, Divide, Radio, Tooltip } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logo from './adelina_new.png';
import { GB, UA, RO } from 'country-flag-icons/react/3x2'
import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';
import roRO from 'antd/locale/ro_RO';
import { ConfigProvider } from 'antd';
import { useLanguage } from '../../utils/hooks/useLanguage';
import { loadState } from '../../utils/localStorage';

import './LoginPage.css';


const { Text } = Typography;

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const auth = loadState('auth');
  const [language, updateLanguage] = useLanguage();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [getToken, { isLoading, isError }] = useGetTokenMutation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const loggedIn = useSelector(isLoggedIn);


  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const reToken = await executeRecaptcha('login');
      await getToken({ username, password, recaptchaToken: reToken }).unwrap();
    } catch (error) {
      setError(error?.error || error?.message);
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    updateLanguage(lang);
    // setRenderKey(Date.now());
  };

  if (loading) {
    return (
      <div className="login-container">
        <Spin size="large" className="login-spinner" />
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-login" />
        <h1 className="logo-text">{t("AI playground")}</h1>
      </div>
      <Form className="login-form" onFinish={handleSubmit} >
        <Form.Item className="login-form-item">
          <Input
            placeholder={t("Username")}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            autoComplete="username"
          />
        </Form.Item>
        <Form.Item className="login-form-item">
          <Input
            placeholder={t("Password")}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
          />
        </Form.Item>
        <Form.Item>
          <Button className="login-form-button" type="primary" htmlType="submit" size="middle" icon={<LoginOutlined />}>
            {t("Log in")}
          </Button>
        </Form.Item>
        {/* {isError && <Text type='danger'>{t(error)}</Text>} */}
      </Form>
    
    {/* <Radio.Group 
      size="small" 
      buttonStyle="solid" 
      onChange={(v)=>{changeLanguage(v.target.value)}} 
      defaultValue={auth?.language}
      className="language-radio-group"
      >
        <Radio.Button className="language-radio" value="en"><GB className="flag-icon"/> {t('English')}</Radio.Button>
        <Radio.Button className="language-radio" value="ua"><UA className="flag-icon"/> {t('Українська')}</Radio.Button>
        <Radio.Button className="language-radio" value="ro"><RO className="flag-icon"/> {t('Română')}</Radio.Button>
    </Radio.Group>
     */}
    </div>
  );
};

export default LoginPage;
