import { Button, Row, Col, Space, Slider, Tooltip } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, DownloadOutlined, LoadingOutlined, AudioOutlined, AudioMutedOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { WaveSurfer, WaveForm } from 'wavesurfer-react';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min';
import dayjs from 'dayjs';
import { isVisible } from '@testing-library/user-event/dist/utils';
import { secondsToTimeFormat } from '../../utils/helpers/dateTime';
import { use } from 'i18next';



const AudioPlayer = forwardRef(({ 
  id, 
  audioUrl, 
  playPause, 
  playAfterLoad, 
  onIsPlayingChange, 
  onCurrentDurationChange, 
  onLoadingChange, 
  token, 
  onError, 
  modalIsVisible,
  startTime
}, ref) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState("00:00:00");
    const [volume, setVolume] = useState(60);
    const waveformId = `waveform-${id}`;
    const timelineId = `timeline-${id}`;
    const [playButtonGhost, setPlayButtonGhost] = useState(true);
    const tokenRef = useRef(token);
    const playAfterLoadRef = useRef(playAfterLoad);
    const wavesurferRef = useRef(null);
    const startTimeRef = useRef(startTime);
    const [playBackRate, setPlayBackRate] = useState(1);
    const [leftChannelMuted, setLeftChannelMuted] = useState(false);
    const [rightChannelMuted, setRightChannelMuted] = useState(false);
    const audioContextRef = useRef(null);
    const filtersRef = useRef({
        input: null,
        splitter: null,
        merger: null,
        leftGain: null,
        rightGain: null
    });
  
    const params = useMemo(() => {
        return {
          splitChannels: true,
          backend: 'WebAudio',
          xhr: { mode: "cors", method: "GET", credentials: "include" },
          plugins: [
            {
              plugin: TimelinePlugin,
              options: {
                container: '#' + timelineId,
              }
            },
            {
              plugin: CursorPlugin,
              options: {
                container: '#' + timelineId,
                showTime: true,
                hideOnBlur: false,
                opacity: 1,
                formatTime: function (time) {
                  return secondsToTimeFormat(parseInt(time));
                  // return dayjs(time * 1000).format('hh:mm:ss');
                },
                customShowTimeStyle: {
                  'background-color': '#7c7c7c',
                  color: '#fff',
                  padding: '1px',
                  'font-size': '8px'
                }
              }
            },
    
          ],
        };
      }, [timelineId]);

    useEffect(() => {
      tokenRef.current = token;
    }, [token]);
    
    useEffect(() => {
      startTimeRef.current = startTime;
    }, [startTime]);

    useEffect(() => {
      playAfterLoadRef.current = playAfterLoad;
    }, [playAfterLoad]);
  
    useEffect(() => {
      if (typeof onIsPlayingChange === 'function') {
        onIsPlayingChange(isPlaying);
        setPlayButtonGhost(!isPlaying);
      }
    }, [isPlaying, onIsPlayingChange]);
  
    useEffect(() => {
      if (wavesurferRef.current) {
        if (playPause) {
          wavesurferRef.current.play();
        } else {
          wavesurferRef.current.pause();
        }
      }
    }, [playPause]);

    const handleStop = () => {
        if (wavesurferRef.current) {
            wavesurferRef.current.stop();
        }
    };
  
    const handleMount = waveSurfer => {
      wavesurferRef.current = waveSurfer;
      if (wavesurferRef.current) {
        if (window) {
          window.surferidze = wavesurferRef.current;
        }
        const context = wavesurferRef.current.backend.ac;
            audioContextRef.current = context;

            const input = context.createGain();
            const splitter = context.createChannelSplitter(2);
            const merger = context.createChannelMerger(2);
            const leftGain = context.createGain();
            const rightGain = context.createGain();

            input.channelCountMode = 'explicit';
            splitter.connect(leftGain, 0);
            splitter.connect(rightGain, 1);
            leftGain.connect(merger, 0, 0);
            rightGain.connect(merger, 0, 1);

            wavesurferRef.current.backend.setFilters([input, splitter, leftGain, merger]);

            filtersRef.current = {
                input,
                splitter,
                merger,
                leftGain,
                rightGain
            };
      }
  
      if (wavesurferRef.current) {
        wavesurferRef.current.on('seek', () => {
          const calculatedTime = new Date(null);
          calculatedTime.setSeconds(parseInt(wavesurferRef.current.getCurrentTime()));
          const newTime = calculatedTime.toISOString().substr(11, 8);
          setCurrentTime(newTime);
          onCurrentDurationChange(parseInt(wavesurferRef.current.getCurrentTime()));
        });
  
        wavesurferRef.current.on('loading', data => {
          onLoadingChange(data);
          setIsLoading(data === 100 ? false : true);
        });
  
        wavesurferRef.current.on('ready', () => {
          if (playAfterLoadRef.current) {
            wavesurferRef.current.seekTo(startTimeRef.current);
            wavesurferRef.current.play();
            setIsPlaying(true);
            
          }
        });
  
        wavesurferRef.current.on('play', () => {
          setIsPlaying(true);
        });
  
        wavesurferRef.current.on('pause', () => {
          setIsPlaying(false);
        });
  
        wavesurferRef.current.on('audioprocess', data => {
          const calculatedTime = new Date(null);
          calculatedTime.setSeconds(parseInt(data)); // setting value in seconds
          const newTime = calculatedTime.toISOString().substr(11, 8);
          setCurrentTime(newTime);
          onCurrentDurationChange(parseInt(data));
        });
  
        wavesurferRef.current.on('finish', () => {
          setIsPlaying(false);
          wavesurferRef.current.seekTo(0);
        });
  
        wavesurferRef.current.on('error', data => {
          onError(data);
        });
      }
    };
  
    const handleUnmount = () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.stop();
        wavesurferRef.current.seekTo(0);
        wavesurferRef.current.unAll();
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }
    };

  
    useEffect(() => {
      if (wavesurferRef.current) {
        wavesurferRef.current.load(audioUrl);
      }
    }, [audioUrl]);
  
    const handleKeyDown = event => {
      if (wavesurferRef.current) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName === 'input' || tagName === 'textarea') {
          return;
        }
    
        if (event.code === 'Space') {
          event.preventDefault();
          wavesurferRef.current.playPause();
        }
    
        if (event.code === 'ArrowLeft') {
          event.preventDefault();
          wavesurferRef.current.skipBackward(5);
        }
    
        if (event.code === 'ArrowRight') {
          event.preventDefault();
          wavesurferRef.current.skipForward(5);
        }
    
        if ((event.metaKey || event.ctrlKey) && event.code === 'ArrowLeft') {
          event.preventDefault();
          wavesurferRef.current.seekTo(0);
        }
    
        if ((event.metaKey || event.ctrlKey) && event.code === 'ArrowRight') {
          event.preventDefault();
          wavesurferRef.current.seekTo(1);
        }
    
        if (event.code === 'KeyM') {
          event.preventDefault();
          if (wavesurferRef.current.getVolume() > 0) {
            setVolume(0);
            wavesurferRef.current.setVolume(0);
          } else {
            setVolume(60);
            wavesurferRef.current.setVolume(volume / 100);
          }
        }
      }
    };
  
    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown, modalIsVisible]);

    const goTo = (seconds) => {
        if (wavesurferRef.current) {
            wavesurferRef.current.seekTo(seconds / wavesurferRef.current.getDuration());
        }
    }

    useImperativeHandle(ref, () => ({
      handleUnmount: handleUnmount,
      handleStop: handleStop,
      goTo: goTo,
    }));
  
    const handlePlayPause = () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.playPause();
      }
    };
  
    const handleVolume = volume => {
      if (wavesurferRef.current) {
        wavesurferRef.current.setVolume(volume / 100);
        setVolume(volume);
      }
    };

    const handlePlayBack = playBackRate => {
      if (wavesurferRef.current) {
        wavesurferRef.current.setPlaybackRate(playBackRate, true);
        setPlayBackRate(playBackRate);
      }
    }

    const handleChannelMute = (channel) => {
      if (!filtersRef.current[`${channel}Gain`]) return;

      if (channel === 'left') {
          filtersRef.current.leftGain.gain.value = leftChannelMuted ? 1 : 0;
          setLeftChannelMuted(!leftChannelMuted);
      } else {
          filtersRef.current.rightGain.gain.value = rightChannelMuted ? 1 : 0;
          setRightChannelMuted(!rightChannelMuted);
      }
    };
  
    return (
      <Row style={{ minWidth: "100%" }}>
        <Col span={2} style={{ justifyContent: "center", display: "flex", flexDirection: "row" }}>
          <Space size={5} direction="vertical">
            <Space size={5} direction="horizontal">
              <Button target="_blank" href={audioUrl} type="primary" ghost><DownloadOutlined /></Button>
              <Button type="primary" ghost={playButtonGhost} onClick={handlePlayPause}>{isLoading ? <LoadingOutlined /> : !isPlaying ? <PlayCircleOutlined /> : <PauseCircleOutlined />}</Button>
            </Space>
            <Space size={1} direction="vertical" style={{ minWidth: "100%" }}>
              <div style={{ textAlign: "center" }}>{currentTime}</div>
              <Slider value={volume} defaultValue={0.6} max={100} onChange={handleVolume} placement="bottom" formatter={<Tooltip title={`Volume ${volume}`} >{`Volume ${volume}`}</Tooltip>} />
            </Space>
          </Space>
        </Col>
        <Col span={2} style={{ justifyContent: "center", display: "flex", flexDirection: "row" }}>
          {/* <Slider vertical value={playBackRate} defaultValue={1.00} min={1.00} max={2.00} step={0.05} marks={{1.00: 1.00, 1.25: 1.25, 1.50: 1.50, 1.75: 1.75, 2.00: 2.00}} onChange={handlePlayBack} placement="bottom" formatter={<Tooltip title={`Speed ${playBackRate}`} >{`Speed ${playBackRate}`}</Tooltip>} /> */}
          <Space size={20} direction="vertical" style={{ minWidth: "100%" }}>
            <Button onClick={() => handleChannelMute('left')} type="primary" ghost={!leftChannelMuted}>
              {leftChannelMuted ? <AudioMutedOutlined /> : <AudioOutlined />}
            </Button>
            <Button onClick={() => handleChannelMute('right')} type="primary" ghost={!rightChannelMuted}>
              {rightChannelMuted ? <AudioMutedOutlined /> : <AudioOutlined />}
            </Button>
          </Space>
        </Col>
        

        <Col span={18} style={{ minWidth: "60%" }}>
          <WaveSurfer
            key={`wskey_${audioUrl}`}
            backend={params.backend}
            waveColor={'#e8b58b'}
            progressColor={'#727cad'}
            xhr={params.xhr}
            splitChannels={params.splitChannels}
            plugins={params.plugins}
            onMount={handleMount}
            height={50}>
            <WaveForm id={waveformId} />
            <div id={timelineId}></div>
          </WaveSurfer>
        </Col>
      </Row>
    );
  });
  
  export default AudioPlayer;