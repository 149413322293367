const en = {
    "Log in": "Log in",
    "Issue Management System": "Issue Management System",
    "Username": "Username",
    "Password": "Password",
    "Log out": "Log out",
    "Tickets": "Tickets",
    "Audiorecords": "Audiorecords",
    "Inbound calls": "Inbound calls",
    "Outbound calls": "Outbound calls",
    "Start date": "Start date",
    "End date": "End date",
    "Today": "Today",
    "Yesterday": "Yesterday",
    "Yesterday+today": "Yesterday+today",
    "Current week": "Current week",
    "Current month": "Current month",
    "Previous week": "Previous week",
    "Previous month": "Previous month",
    "Phone number": "Phone number",
    "Theme": "Theme",
    "Sub theme": "Sub theme",
    "Status": "Status",
    "New": "New",
    "In progress": "In progress",
    "Closed": "Closed",
    "Product name": "Product name",
    "Production date": "Production date",
    "Batch": "Batch",
    "Manufacturer number": "Manufacturer number",
    "Name": "Name",
    "Phone": "Phone",
    "Call date": "Call date",
    "Agent": "Agent",
    "Critical": "Critical",
    "Yes": "Yes",
    "No": "No",
    "Shop city": "Shop city",
    "Shop name": "Shop name",
    "Shop address": "Shop address",
    "ID": "ID",
    "Product": "Product",
    "City": "City",
    "Description": "Description",
    "Comment": "Comment",
    "Comments": "Comments",
    "Add comment": "Add comment",
    "Show all": "Show all",
    "Hide": "Hide",
    "Write your comment here": "Write your comment here",
    "Call audio record": "Call audio record",
    "added a comment": "added a comment",
    "Change status to:": "Change status to:",
    "Change status?": "Change status?",
    "Are you sure to the status of this task?": "Are you sure to the status of this task?",
    "Ticket": "Ticket",
    "Download": "Download",
    "Play": "Play",
    "Pause": "Pause",
    "Language": "Language",
    "English": "English",
    "Українська": "Ukrainian",
    "Ukrainian": "Ukrainian",
    "Română": "Romanian",
    "Sorry, the page you visited does not exist.": "Sorry, the page you visited does not exist.",
    "Return": "Return",
    "Sorry, you are not authorized to access this page.": "Sorry, you are not authorized to access this page.",
    "Sorry, this page is under construction.": "Sorry, this page is under construction.",
    "Clear all filters": "Clear all filters",
    "Status changes history": "Status changes history",
    "Copy ticket link to clipboard": "Copy ticket link to clipboard",
    "Show test records. Checkbox visible only for admin group.": "Show test records. Checkbox visible only for admin group.",
    "Show test records": "Show test records",
    "Test": "Test",
    "Outbound transfer": "Outbound transfer",
    "Outbound callback": "Outbound callback",
    "Start call": "Start call",
    "End call": "End call",
    "Duration": "Duration",
    "s": "s",
    "Call type": "Call type",
    "Agent number": "Agent number",
    "Start": "Start",
    "End": "End",
    "Agent name": "Agent name",
    "Wait": "Wait",
    "Talk": "Talk",
    "Hold": "Hold",
    "End reason": "End reason",
    "Search": "Search",
    "Last 7 days": "Last 7 days",
    "Call audio record will be available soon.": "Call audio record will be available soon.",
    "The call audio record will be available approximately two hours after its completion.": "The call audio record will be available approximately two hours after its completion.",
    "The caller release the call": "The caller release the call",
    "The system release the call": "The system release the call",
    "The targeted pilot is closed, the call is redirected to an other pilot.": "The targeted pilot is closed, the call is redirected to an other pilot.",
    "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.": "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.",
    "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.": "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.",
    "The ringing overflow timer redirected the call to a pilot.": "The ringing overflow timer redirected the call to a pilot.",
    "The targeted pilot is closed, the call is redirected to an agent.": "The targeted pilot is closed, the call is redirected to an agent.",
    "The targeted pilot is saturated, the call is redirected to an agent.": "The targeted pilot is saturated, the call is redirected to an agent.",
    "The waiting time overflow timer redirected the call to an agent .": "The waiting time overflow timer redirected the call to an agent .",
    "The ringing overflow timer redirected the call to an agent.": "The ringing overflow timer redirected the call to an agent.",
    "The targeted pilot is closed, the call is redirected to an address (not an acd device).": "The targeted pilot is closed, the call is redirected to an address (not an acd device).",
    "The targeted pilot is saturated, the call is redirected to an address (not an acd device).": "The targeted pilot is saturated, the call is redirected to an address (not an acd device).",
    "The waiting time overflow timer redirected the call to an address (not an acd device).": "The waiting time overflow timer redirected the call to an address (not an acd device).",
    "The ringing overflow timer redirected the call to an address (not an acd device).": "The ringing overflow timer redirected the call to an address (not an acd device).",
    "The call was ringing an agent, another agent of the different processing group picked'up the call.": "The call was ringing an agent, another agent of the different processing group picked'up the call.",
    "The call was ringing an agent when a non monitored device picked?up the call.": "The call was ringing an agent when a non monitored device picked?up the call.",
    "An acd_call was transferred to a pilot by an agent.": "An acd_call was transferred to a pilot by an agent.",
    "An acd_call was transferred to an agent of the same processing group by an agent.": "An acd_call was transferred to an agent of the same processing group by an agent.",
    "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer": "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer",
    "An acd_call was transferred by an agent to an address (non acd device).": "An acd_call was transferred by an agent to an address (non acd device).",
    "A non acd_call was transferred by an agent to a pilot.": "A non acd_call was transferred by an agent to a pilot.",
    "A non acd_call was transferred by an agent to an agent belonging to the same processing group.": "A non acd_call was transferred by an agent to an agent belonging to the same processing group.",
    "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).": "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).",
    "A non acd_call was transferred by an agent to an address (not an acd device).": "A non acd_call was transferred by an agent to an address (not an acd device).",
    "A non_acd call ringing an agent was picked?up by a non monitored device.": "A non_acd call ringing an agent was picked?up by a non monitored device.",
    "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).": "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).",
    "Called released the call (mainly an agent or a non monitored device).": "Called released the call (mainly an agent or a non monitored device).",
    "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).": "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).",
    "The call was ringing an agent, an other agent of the same processing group picked?up the call.": "The call was ringing an agent, an other agent of the same processing group picked?up the call.",
    "An acd call is distributed on a resource which is not an agent.": "An acd call is distributed on a resource which is not an agent.",
    "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).": "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).",
    "An acd_call was transferred to a non assigned agent, the call becomes a non acd call": "An acd_call was transferred to a non assigned agent, the call becomes a non acd call",
    "A call was released due to the agent's unplugged phone set": "A call was released due to the agent's unplugged phone set",
    "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.": "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.",
    "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.": "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.",
    "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.": "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.",
    "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.": "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.",
    "Call is suspended.": "Call is suspended.",
    "Ringing rotation for an ACR call distributed to a dedicated pilot": "Ringing rotation for an ACR call distributed to a dedicated pilot",
    "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.": "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.",
    "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th": "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th",
    "s": "s",
    "h": "h",
    "Speech to text playground": "Speech to text playground",
    "Project ID": "Project ID",
    "Project name": "Project name",
    "Calls count": "Calls count",
    "Calls with transcription": "Calls with transcription",
    "Transcriptions count": "Transcriptions count",
    "Transcription time": "Transcription time",
    "Top 10 projects statistics": "Top 10 projects statistics",
    "Project": "Project",
    "Pilot": "Pilot",
    "Access code": "Access code",
    "Mars ID": "Mars ID",
    "Total statistics": "Total statistics",
    "Transcription": "Transcription",
    "Copied to clipboard": "Copied to clipboard",
    "Here it will be ability to create a transcription with custom parameters": "Here it will be ability to create a transcription with custom parameters",
    "transcription details": "transcription details",
    "Show": "Show",
    "Show full transcription": "Show full transcription",
    "Show linked to timecode": "Show linked to timecode",
    "Transcription date": "Transcription date",
    "Transcription execution time": "Transcription execution time",
    "Model": "Model",
    "Language": "Language",
    "Condition on previous text": "Condition on previous text",
    "Temperature": "Temperature",
    "Word timestamps": "Word timestamps",
    "Initial prompt": "Initial prompt",
    "Max initial timestamp": "Max initial timestamp",
    "No speech threshold": "No speech threshold",
    "Agent CCA": "Agent CCA",
    "Call theme": "Call theme",
    "Call sub theme": "Call sub theme",
    "Call details": "Call details",
    "Calls without transcription in queue": "Calls without transcription in queue",
    "Duration without transcription in queue": "Duration without transcription in queue",
    "Profile": "Profile",
    "Settings": "Settings",
    "Logout": "Logout",
    "Wait for timestamp with transcription": "Wait for timestamp with transcription",
    "There is no available transcription": "There is no available transcription",
    "Projects statistics": "Projects statistics",
    "Show less": "Show less",
    "Show more": "Show more",
    "With transcription": "With transcription",
    "Hide additional filters": "Hide additional filters",
    "Show additional filters": "Show additional filters",
    "Send request to get access": "Send request to get access",
    "Issue": "Issue",
    "Errors": "Errors",
    "New ai chat": "New ai chat",
    "Cancel": "Cancel",
    "Save": "Save",
    "New ai chat": "New ai chat",
    "AI Chat": "AI Chat",
    "Add new chat": "Add new chat",
    "Send message": "Send message",
    "Delete this chat?": "Delete this chat?",
    "Type your message here...": "Type your message here...",
    "Create new chat?": "Create new chat?",
    "Chat name": "Chat name",
    "AI playground": "AI playground",
    "Invalid username or password": "Invalid username or password",
    "IVR time": "IVR time",
    "Wait time": "Wait time",
    "System context": "System context",
    "Answer settings": "Answer settings",
    "video": "video",//
    "File Name": "File Name",
    "Upload date": "Upload date",
    "Open": "Open",
    "Transcribe": "Transcribe",
    "Actions": "Actions",
    "Sure to delete?": "Sure to delete?",
    "Click or drag audio file to this area to upload": "Click or drag audio file to this area to upload",
    "wav or mp3 file are supported": "wav or mp3 file are supported",
    "New ai chat (GPT 4 engine)": "New ai chat (GPT 4 engine)",
    "Unknown": "Unknown",
    "Select a Project": "Select a Project",
    "Temperature parameter determines the level of creativity and randomness of text generation. If you want stable and predictable results, use values in the range of 0.1 - 0.5. For experimental or creative tasks, you can try 0.7 - 1.0.": "Temperature parameter determines the level of creativity and randomness of text generation. If you want stable and predictable results, use values in the range of 0.1 - 0.5. For experimental or creative tasks, you can try 0.7 - 1.0.",
    "The Language parameter sets the language that the model will use for recognition or transliteration. Choosing the correct language improves the accuracy of the results.": "The Language parameter sets the language that the model will use for recognition or transliteration. Choosing the correct language improves the accuracy of the results.",
    "Select language": "Select language",
    "Automatic": "Automatic",
    "Hallucination Silence Threshold": "Hallucination Silence Threshold",
    "The Hallucination Silence Threshold parameter determines the duration of silence that the system interprets as a hallucination. Low values (0-2): Quick response to pauses, suitable for speech without long pauses. High values (5-60): For audio with significant pauses to avoid false interpretation of silence.": "The Hallucination Silence Threshold parameter determines the duration of silence that the system interprets as a hallucination. Low values (0-2): Quick response to pauses, suitable for speech without long pauses. High values (5-60): For audio with significant pauses to avoid false interpretation of silence.",
    "Initial Prompt sets the initial text or question for the model. Use it to control the context or direction of the response. This helps shape responses according to expectations.": "Initial Prompt sets the initial text or question for the model. Use it to control the context or direction of the response. This helps shape responses according to expectations.",
    "Suppress Tokens": "Suppress Tokens",
    "Suppress Tokens is a parameter that allows you to exclude certain tokens (words or characters) from the results of the model generation. It is used to avoid unwanted words or phrases in the responses.": "Suppress Tokens is a parameter that allows you to exclude certain tokens (words or characters) from the results of the model generation. It is used to avoid unwanted words or phrases in the responses.",
    "Hotwords": "Hotwords",
    "Hotwords is a set of words or phrases that receive higher priority during processing of audio or text. This allows the system to better recognize or highlight specific key words.": "Hotwords is a set of words or phrases that receive higher priority during processing of audio or text. This allows the system to better recognize or highlight specific key words.",
    "Word Timestamps allow you to get the exact time of each word in the audio file. This is useful for synchronizing subtitles or other applications that require tracking the time of words.": "Word Timestamps allow you to get the exact time of each word in the audio file. This is useful for synchronizing subtitles or other applications that require tracking the time of words.",
    "Settings saved successfully": "Settings saved successfully",
    "Error saving settings": "Error saving settings",
    "QA": "QA",
    "QA Settings Coming Soon": "QA Settings Coming Soon",
    "End cause": "End cause"
};

const ua = {
    "Log in": "Увійти",
    "Issue Management System": "Система управління зверненнями",
    "Username": "Ім'я користувача",
    "Password": "Пароль",
    "Log out": "Вийти",
    "Tickets": "Скарги",
    "Audiorecords": "Аудіозаписи",
    "Inbound calls": "Вхідні дзвінки",
    "Outbound calls": "Вихідні дзвінки",
    "Start date": "Дата початку",
    "End date": "Дата закінчення",
    "Today": "Сьогодні",
    "Yesterday": "Вчора",
    "Yesterday+today": "Вчора+сьогодні",
    "Current week": "Поточний тиждень",
    "Current month": "Поточний місяць",
    "Previous week": "Попередній тиждень",
    "Previous month": "Попередній місяць",
    "Phone number": "Номер телефону",
    "Theme": "Тема",
    "Sub theme": "Підтема",
    "Status": "Статус",
    "New": "Нова",
    "In progress": "В роботі",
    "Closed": "Завершена",
    "Product name": "Назва продукту",
    "Production date": "Дата виробництва",
    "Batch": "Партія",
    "Manufacturer number": "Номер виробника",
    "Name": "Ім'я",
    "Phone": "Телефон",
    "Call date": "Дата дзвінка",
    "Agent": "Агент",
    "Critical": "Критичність",
    "Yes": "Так",
    "No": "Ні",
    "Shop city": "Місто магазину",
    "Shop name": "Назва магазину",
    "Shop address": "Адреса магазину",
    "ID": "ID",
    "Product": "Продукт",
    "City": "Місто",
    "Description": "Опис",
    "Comment": "Коментар",
    "Comments": "Коментарі",
    "Add comment": "Додати коментар",
    "Show all": "Показати усі",
    "Hide": "Сховати",
    "Write your comment here": "Напишіть свій коментар тут",
    "Call audio record": "Аудіозапис дзвінка",
    "added a comment": "додав(ла) коментар",
    "Change status to:": "Змінити статус на:",
    "Change status?": "Змінити статус?",
    "Are you sure to the status of this task?": "Ви впевнені, що хочете змінити статус цієї скарги?",
    "Ticket": "Скарга",
    "Download": "Завантажити",
    "Play": "Відтворити",
    "Pause": "Пауза",
    "Language": "Мова",
    "English": "Англійська",
    "Українська": "Українська",
    "Ukrainian": "Українська",
    "Română": "Румунська",
    "Sorry, the page you visited does not exist.": "Вибачте, але сторінка, яку ви відвідали, не існує.",
    "Return": "Повернутись",
    "Sorry, you are not authorized to access this page.": "Вибачте, але у вас немає доступу до цієї сторінки.",
    "Sorry, this page is under construction.": "Вибачте, але ця сторінка знаходиться в розробці.",
    "Clear all filters": "Очистити всі фільтри",
    "Status changes history": "Історія зміни статусів",
    "Copy ticket link to clipboard": "Скопіювати посилання на скаргу",
    "Show test records. Checkbox visible only for admin group.": "Показати тестові записи. Checkbox видимий тільки для групи адміністраторів.",
    "Show test records": "Показати тестові записи",
    "Test": "Тест",
    "Outbound transfer": "Переключення",
    "Outbound callback": "Передзвони",
    "Start call": "Початок дзвінка",
    "End call": "Кінець дзвінка",
    "Duration": "Тривалість",
    "s": "с",
    "Call type": "Тип дзвінка",
    "Agent number": "Номер агента",
    "Start": "Початок",
    "End": "Кінець",
    "Agent name": "Ім'я агента",
    "Wait": "Очікування",
    "Talk": "Розмова",
    "Hold": "Утримання",
    "End reason": "Причина завершення",
    "Search": "Пошук",
    "Last 7 days": "Останні 7 днів",
    "Call audio record will be available soon.": "Аудіозапис дзвінка буде доступний найближчим часом.",
    "The call audio record will be available approximately two hours after its completion.": "Аудіозапис дзвінка буде доступний приблизно через дві години після його завершення.",
    "The caller release the call": "Абонент завершив дзвінок",
    "The system release the call": "Система завершила дзвінок",
    "The targeted pilot is closed, the call is redirected to an other pilot.": "Цільовий пілот закритий, дзвінок перенаправляється на інший пілот.",
    "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.": "Цільовий пілот переповнений (його звичайна черга(и) переповнена), дзвінок перенаправляється на інший пілот.",
    "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.": "Таймер переповнення часу очікування перенаправив дзвінок на пілот, або у разі ACR-дзвінка, використано правило вибору, яке перенаправило дзвінок на пілот.",
    "The ringing overflow timer redirected the call to a pilot.": "Таймер переповнення дзвінка перенаправив дзвінок на пілот.",
    "The targeted pilot is closed, the call is redirected to an agent.": "Цільовий пілот закритий, дзвінок перенаправляється на оператора.",
    "The targeted pilot is saturated, the call is redirected to an agent.": "Цільовий пілот переповнений, дзвінок перенаправляється на оператора.",
    "The waiting time overflow timer redirected the call to an agent .": "Таймер переповнення часу очікування перенаправив дзвінок на оператора.",
    "The ringing overflow timer redirected the call to an agent.": "Таймер переповнення дзвінка перенаправив дзвінок на оператора.",
    "The targeted pilot is closed, the call is redirected to an address (not an acd device).": "Цільовий пілот закритий, дзвінок перенаправляється на адресу (не на пристрій ACD).",
    "The targeted pilot is saturated, the call is redirected to an address (not an acd device).": "Цільовий пілот переповнений, дзвінок перенаправляється на адресу (не на пристрій ACD).",
    "The waiting time overflow timer redirected the call to an address (not an acd device).": "Таймер переповнення часу очікування перенаправив дзвінок на адресу (не на пристрій ACD).",
    "The ringing overflow timer redirected the call to an address (not an acd device).": "Таймер переповнення дзвінка перенаправивдзвінок на адресу (не на пристрій ACD).",
    "The call was ringing an agent, another agent of the different processing group picked'up the call.": "Дзвінок дзвонив оператору, інший оператор із різної групи обробки відповів на дзвінок.",
    "The call was ringing an agent when a non monitored device picked?up the call.": "Дзвінок дзвонив оператору, коли його підхопив несупроводжуваний пристрій.",
    "An acd_call was transferred to a pilot by an agent.": "ACD-дзвінок був перенаправлений на пілота оператором.",
    "An acd_call was transferred to an agent of the same processing group by an agent.": "ACD-дзвінок був перенаправлений на оператора зі своєї ж групи обробки оператором.",
    "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer": "ACD-дзвінок був перенаправлений на оператора, що належить до іншої групи обробки, аніж оператор, який виконав перенаправлення",
    "An acd_call was transferred by an agent to an address (non acd device).": "ACD-дзвінок був перенаправлений оператором на адресу (не на пристрій ACD).",
    "A non acd_call was transferred by an agent to a pilot.": "Не ACD-дзвінок був перенаправлений оператором на пілота.",
    "A non acd_call was transferred by an agent to an agent belonging to the same processing group.": "Не ACD-дзвінок був перенаправлений оператором на оператора, що належить до тієї ж групи обробки.",
    "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).": "Не ACD-дзвінок був перенаправлений оператором на іншого оператора, який не належить до тієї ж групи обробки (або не призначений).",
    "A non acd_call was transferred by an agent to an address (not an acd device).": "Не ACD-дзвінок був перенаправлений оператором на адресу (не на пристрій ACD).",
    "A non_acd call ringing an agent was picked?up by a non monitored device.": "Не ACD-дзвінок, який дзвонив оператору, був підхоплений несупроводжуваним пристроєм.",
    "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).": "Комунікація закінчується внаслідок неприродної причини: вилучено з'єднувач набору оператора, вилучено телефонний набір оператора...).",
    "Called released the call (mainly an agent or a non monitored device).": "Викликаючий завершив дзвінок (головним чином, оператор або несупроводжуваний пристрій).",
    "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).": "ACD-дзвінок відхилено. ACD-дзвінок подається на пілота, немає доступних ресурсів для обробки дзвінка (див. Примітку 1).",
    "The call was ringing an agent, an other agent of the same processing group picked?up the call.": "Дзвінок дзвонив оператору, інший оператор зі тієї ж групи обробки підхопив дзвінок.",
    "An acd call is distributed on a resource which is not an agent.": "ACD-дзвінок розподіляється на ресурс, який не є оператором.",
    "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).": "ACD-дзвінок підхоплюється не призначеним оператором (дзвінок стає не-ACD дзвінком).",
    "An acd_call was transferred to a non assigned agent, the call becomes a non acd call": "ACD-дзвінок був перенаправлений на не призначеного оператора, дзвінок стає не-ACD дзвінком",
    "A call was released due to the agent's unplugged phone set": "Дзвінок було звільнено через відключення телефонного набору оператора",
    "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.": "Дзвінок, що підключений до IVR під час очікування у черзі (функція IVR у черзі), був вилучений з черги саме IVR.",
    "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.": "Для ACR-дзвінка в режимі очікування відбулась зміна режиму вибору або списку атрибутів (навички, експертність, характер).",
    "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.": "Відбулась ротація ACR, новий обраний оператор і попередній належать до однієї й тієї ж групи обробки.",
    "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.": "Відбулась ротація ACR, новий обраний оператор і попередній належать до двох різних груп обробки.",
    "Call is suspended.": "Дзвінок призупинено.",
    "Ringing rotation for an ACR call distributed to a dedicated pilot": "Обертання дзвінка для ACR, розподіленого на призначений пілот.",
    "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.": "ACR-дзвінок, надісланий на призначений пілот (мережевий ACR), не обслуговується і повертається на локальний вузол.",
    "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th": "Запитувальний дзвінок адресований оператору. Коли викликаючий відпускає цей запитувальний дзвінок шляхом перенаправлення, комунікаційний квиток для нього",
    "s": "с",
    "h": "г",
    "Speech to text playground": "Speech to text playground",
    "Project ID": "ID проекту",
    "Project name": "Назва проекту",
    "Calls count": "Кількість дзвінків",
    "Calls with transcription": "Дзвінків з транскрипцією",
    "Transcriptions count": "Кількість транскрипцій",
    "Transcription time": "Час транскрипції",
    "Top 10 projects statistics": "Статистика топ-10 проектів",
    "Project": "Проект",
    "Pilot": "Пілот",
    "Access code": "Бізнес-код",
    "Mars ID": "ID аудіозапису",
    "Total statistics": "Загальна статистика",
    "Transcription": "Транскрипція",
    "Copied to clipboard": "Скопійовано в буфер обміну",
    "Here it will be ability to create a transcription with custom parameters": "Тут буде можливість створити транскрипцію зі кастомними параметрами",
    "transcription details": "деталі транскрипції",
    "Show": "Показати",
    "Show full transcription": "Показати повну транскрипцію",
    "Show linked to timecode": "Показати пов'язано з часовим кодом",
    "Transcription date": "Дата транскрипції",
    "Transcription execution time": "Час створення транскрипції",
    "Model": "Модель",
    "Language": "Мова",
    "Condition on previous text": "Використання попереднього тексту як контексту",
    "Temperature": "Температура",
    "Word timestamps": "Часові мітки слів",
    "Initial prompt": "Початковий промпт",
    "Max initial timestamp": "Максимальна початкова мітка часу",
    "No speech threshold": "Поріг відсутності мовлення",
    "Agent CCA": "CCA агента",
    "Call theme": "Тема дзвінка",
    "Call sub theme": "Підтема дзвінка",
    "Call details": "Деталі дзвінка",
    "Calls without transcription in queue": "Дзвінків без транскрипції в черзі",
    "Duration without transcription in queue": "Тривалість без транскрипції в черзі",
    "Profile": "Профіль",
    "Settings": "Налаштування",
    "Logout": "Вийти",
    "Wait for timestamp with transcription": "Чекайте мітки часу з транскрипцією",
    "There is no available transcription": "Немає доступної транскрипції",
    "Projects statistics": "Статистика проектів",
    "Show less": "Показати менше",
    "Show more": "Показати більше",
    "With transcription": "З транскрипцією",
    "Hide additional filters": "Сховати додаткові фільтри",
    "Show additional filters": "Показати додаткові фільтри",
    "Send request to get access": "Надіслати запит на отримання доступу",
    "Issue": "Тікет",
    "Errors": "Помилки",
    "New ai chat": "Новий аі чат",
    "Cancel": "Скасувати",
    "Save": "Зберегти",
    "New ai chat": "Новий ai чат",
    "AI Chat": "AI чат",
    "Add new chat": "Додати новий чат",
    "Send message": "Надіслати повідомлення",
    "Delete this chat?": "Видалити цей чат?",
    "Type your message here...": "Введіть своє повідомлення тут ...",
    "Create new chat?": "Створити новий чат?",
    "Chat name": "Назва чату",
    "AI playground": "AI площадка",
    "Invalid username or password": "Невірне ім'я користувача або пароль",
    "IVR time": "Час IVR",
    "Wait time": "Час очікування",
    "System context": "Системний контекст",
    "Answer settings": "Налаштування відповіді",
    "video": "відео",
    "File Name": "Назва файлу",
    "Upload date": "Дата завантаження",
    "Open": "Відкрити",
    "Transcribe": "Транскрибувати",
    "Actions": "Дії",
    "Sure to delete?": "Впевнені, що хочете видалити?",
    "Click or drag audio file to this area to upload": "Клацніть або перетягніть аудіофайл в цю область для завантаження",
    "wav or mp3 file are supported": "підтримуються файли wav або mp3",
    "New ai chat (GPT 4 engine)": "Новий ai чат (GPT 4)",
    "Unknown": "Невідомо",
    "Select a Project": "Виберіть проект",
    "Temperature parameter determines the level of creativity and randomness of text generation. If you want stable and predictable results, use values in the range of 0.1 - 0.5. For experimental or creative tasks, you can try 0.7 - 1.0.": "Параметр температури визначає рівень креативності та випадковості генерації тексту. Якщо ви хочете стабільні та передбачувані результати, використовуйте значення в діапазоні 0.1 - 0.5. Для експериментальних або творчих завдань можна спробувати 0.7 - 1.0.",
    "The Language parameter sets the language that the model will use for recognition or transliteration. Choosing the correct language improves the accuracy of the results.": "Параметр мови встановлює мову, яку модель використовуватиме для розпізнавання або транслітерації. Вибір правильної мови покращує точність результатів.",
    "Select language": "Оберіть мову",
    "Automatic": "Автоматично",
    "Hallucination Silence Threshold": "Поріг тиші галюцинацій",
    "The Hallucination Silence Threshold parameter determines the duration of silence that the system interprets as a hallucination. Low values (0-2): Quick response to pauses, suitable for speech without long pauses. High values (5-60): For audio with significant pauses to avoid false interpretation of silence.": "Параметр порогу тиші галюцинацій визначає тривалість тиші, яку система інтерпретує як галюцинацію. Низькі значення (0-2): Швидка реакція на паузи, підходить для мовлення без довгих пауз. Високі значення (5-60): Для аудіо зі значними паузами, щоб уникнути помилкової інтерпретації тиші.",
    "Initial Prompt sets the initial text or question for the model. Use it to control the context or direction of the response. This helps shape responses according to expectations.": "Початковий промпт встановлює початковий текст або питання для моделі. Використовуйте його для контролю контексту або напрямку відповіді. Це допомагає формувати відповіді відповідно до очікувань.",
    "Suppress tokens": "Пригнічення токенів",
    "Suppress Tokens is a parameter that allows you to exclude certain tokens (words or characters) from the results of the model generation. It is used to avoid unwanted words or phrases in the responses.": "Пригнічення токенів - це параметр, який дозволяє виключити певні токени (слова або символи) з результатів генерації моделі. Використовується для уникнення небажаних слів або фраз у відповідях.",
    "Hotwords": "Ключові слова",
    "Hotwords is a set of words or phrases that receive higher priority during processing of audio or text. This allows the system to better recognize or highlight specific key words.": "Ключові слова - це набір слів або фраз, які отримують вищий пріоритет під час обробки аудіо або тексту. Це дозволяє системі краще розпізнавати або виділяти конкретні ключові слова.",
    "Word Timestamps allow you to get the exact time of each word in the audio file. This is useful for synchronizing subtitles or other applications that require tracking the time of words.": "Часові мітки слів дозволяють отримати точний час кожного слова в аудіофайлі. Це корисно для синхронізації субтитрів або інших додатків, які потребують відстеження часу слів.",
    "Settings saved successfully": "Налаштування успішно збережено",
    "Error saving settings": "Помилка збереження налаштувань",
    "QA": "QA",
    "QA Settings Coming Soon": "Налаштування QA скоро з'являться",
    "End cause": "Причина завершення"
};

const ro = {
    "Log in": "Conectare",
    "Issue Management System": "Sistem de Gestionare a Problemelor",
    "Username": "Nume de Utilizator",
    "Password": "Parolă",
    "Log out": "Deconectare",
    "Tickets": "Tichete",
    "Audiorecords": "Înregistrări Audio",
    "Inbound calls": "Apeluri de Intrare",
    "Outbound calls": "Apeluri de Ieșire",
    "Start date": "Data de Început",
    "End date": "Data de Sfârșit",
    "Today": "Astăzi",
    "Yesterday": "Ieri",
    "Yesterday+today": "Ieri+Azi",
    "Current week": "Săptămâna Curentă",
    "Current month": "Luna Curentă",
    "Previous week": "Săptămâna Anterioară",
    "Previous month": "Luna Anterioară",
    "Phone number": "Număr de Telefon",
    "Theme": "Temă",
    "Sub theme": "Subtemă",
    "Status": "Stare",
    "New": "Nou",
    "In progress": "În Progres",
    "Closed": "Închis",
    "Product name": "Nume Produs",
    "Production date": "Data Producerii",
    "Batch": "Lot",
    "Manufacturer number": "Număr Producător",
    "Name": "Nume",
    "Phone": "Telefon",
    "Call date": "Data Apelului",
    "Agent": "Agent",
    "Critical": "Critic",
    "Yes": "Da",
    "No": "Nu",
    "Shop city": "Oraș Magazin",
    "Shop name": "Nume Magazin",
    "Shop address": "Adresă Magazin",
    "ID": "ID",
    "Product": "Produs",
    "City": "Oraș",
    "Description": "Descriere",
    "Comment": "Comentariu",
    "Comments": "Comentarii",
    "Add comment": "Adaugă Comentariu",
    "Show all": "Afișează Tot",
    "Hide": "Ascunde",
    "Write your comment here": "Scrieți Comentariul Aici",
    "Call audio record": "Înregistrare Audio a Apelului",
    "added a comment": "a adăugat un comentariu",
    "Change status to:": "Schimbă Starea la:",
    "Change status?": "Schimbă Starea?",
    "Are you sure to the status of this task?": "Sunteți sigur cu privire la starea acestei sarcini?",
    "Ticket": "Bilet",
    "Download": "Descarcă",
    "Play": "Redă",
    "Pause": "Pauză",
    "Language": "Limba",
    "English": "Engleză",
    "Українська": "Ucraineană",
    "Ukrainian": "Ucraineană",
    "Română": "Rumună",
    "Sorry, the page you visited does not exist.": "Ne pare rău, pagina pe care ați vizitat-o nu există.",
    "Return": "Înapoi",
    "Sorry, you are not authorized to access this page.": "Ne pare rău, nu aveți autorizație pentru a accesa această pagină.",
    "Sorry, this page is under construction.": "Ne pare rău, această pagină este în construcție.",
    "Clear all filters": "Șterge Toate Filtrele",
    "Status changes history": "Istoricul Schimbărilor de Stare",
    "Copy ticket link to clipboard": "Copiază Legătura Biletului în Clipboard",
    "Show test records. Checkbox visible only for admin group.": "Afișează Înregistrări de Test. Casetă vizibilă doar pentru grupul de admini.",
    "Show test records": "Afișează Înregistrări de Test",
    "Test": "Test",
    "Outbound transfer": "Transfer de Ieșire",
    "Outbound callback": "Apel Înapoi de Ieșire",
    "Start call": "Începe Apelul",
    "End call": "Încheie Apelul",
    "Duration": "Durată",
    "s": "s",
    "Call type": "Tip Apel",
    "Agent number": "Număr Agent",
    "Start": "Început",
    "End": "Sfârșit",
    "Agent name": "Nume Agent",
    "Wait": "Așteaptă",
    "Talk": "Vorbește",
    "Hold": "Așteptare",
    "End reason": "Motivul Sfârșitului",
    "Search": "Căutare",
    "Last 7 days": "Ultimele 7 Zile",
    "Call audio record will be available soon.": "Înregistrarea audio a apelului va fi disponibilă în curând.",
    "The call audio record will be available approximately two hours after its completion.": "Înregistrarea audio a apelului va fi disponibilă aproximativ două ore după încheierea sa.",
    "The caller release the call": "Apelantul a încheiat apelul",
    "The system release the call": "Sistemul a încheiat apelul",
    "The targeted pilot is closed, the call is redirected to an other pilot.": "Pilotul vizat este închis, apelul este redirecționat către alt pilot.",
    "The targeted pilot is saturated (its regular queue(s) is saturated), the call is redirected to an other pilot.": "Pilotul vizat este suprasaturat (cozile sale regulate sunt suprasaturate), apelul este redirecționat către alt pilot.",
    "The waiting time overflow timer redirected the call to a pilot or in case of an ACR call, the used selection rule redirected the selection rule redirected the call to a pilot.": "Temporizatorul de depășire a timpului de așteptare a redirecționat apelul către un pilot sau, în cazul unui apel ACR, regulile de selecție utilizate au redirecționat apelul către un pilot.",
    "The ringing overflow timer redirected the call to a pilot.": "Temporizatorul de depășire a timpului de sonerie a redirecționat apelul către un pilot.",
    "The targeted pilot is closed, the call is redirected to an agent.": "Pilotul vizat este închis, apelul este redirecționat către un agent.",
    "The targeted pilot is saturated, the call is redirected to an agent.": "Pilotul vizat este suprasaturat, apelul este redirecționat către un agent.",
    "The waiting time overflow timer redirected the call to an agent .": "Temporizatorul de depășire a timpului de așteptare a redirecționat apelul către un agent.",
    "The ringing overflow timer redirected the call to an agent.": "Temporizatorul de depășire a timpului de sonerie a redirecționat apelul către un agent.",
    "The targeted pilot is closed, the call is redirected to an address (not an acd device).": "Pilotul vizat este închis, apelul este redirecționat către o adresă (nu un dispozitiv ACD).",
    "The targeted pilot is saturated, the call is redirected to an address (not an acd device).": "Pilotul vizat este suprasaturat, apelul este redirecționat către o adresă (nu un dispozitiv ACD).",
    "The waiting time overflow timer redirected the call to an address (not an acd device).": "Temporizatorul de depășire a timpului de așteptare a redirecționat apelul către o adresă (nu un dispozitiv ACD).",
    "The ringing overflow timer redirected the call to an address (not an acd device).": "Temporizatorul de depășire a timpului de sonerie a redirecționat apelul către o adresă (nu un dispozitiv ACD).",
    "The call was ringing an agent, another agent of the different processing group picked'up the call.": "Apelul suna un agent, alt agent dintr-un grup de procesare diferit a preluat apelul.",
    "The call was ringing an agent when a non monitored device picked?up the call.": "Apelul suna un agent când un dispozitiv ne-monitorizat a preluat apelul.",
    "An acd_call was transferred to a pilot by an agent.": "Un apel ACD a fost transferat către un pilot de către un agent.",
    "An acd_call was transferred to an agent of the same processing group by an agent.": "Un apel ACD a fost transferat către un agent din același grup de procesare de către un agent.",
    "An acd_call was transferred to an agent belonging to a different processing group of agent?s one who performed the transfer": "Un apel ACD a fost transferat către un agent care aparține unui grup de procesare diferit față de cel al agentului care a efectuat transferul.",
    "An acd_call was transferred by an agent to an address (non acd device).": "Un apel ACD a fost transferat de către un agent către o adresă (dispozitiv non-ACD).",
    "A non acd_call was transferred by an agent to a pilot.": "Un apel non-ACD a fost transferat de către un agent către un pilot.",
    "A non acd_call was transferred by an agent to an agent belonging to the same processing group.": "Un apel non-ACD a fost transferat de către un agent către un alt agent care aparține aceluiași grup de procesare.",
    "A non acd_call was transferred by an agent to an other agent not belonging to the same processing group (or not assigned).": "Un apel non-ACD a fost transferat de către un agent către un alt agent care nu aparține aceluiași grup de procesare (sau nu a fost asignat).",
    "A non acd_call was transferred by an agent to an address (not an acd device).": "Un apel non-ACD a fost transferat de către un agent către o adresă (nu un dispozitiv ACD).",
    "A non_acd call ringing an agent was picked?up by a non monitored device.": "Un apel non-ACD care suna un agent a fost preluat de un dispozitiv ne-monitorizat.",
    "Communication ends due to an abnormal cause : coupler of agent's set was unplugged, agent?s phone set was unplugged...).": "Comunicarea se încheie din cauza unei cauze anormale: mufa setului agentului a fost scosă, setul de telefon al agentului a fost scos...).",
    "Called released the call (mainly an agent or a non monitored device).": "Apelantul a încheiat apelul (în principal un agent sau un dispozitiv ne-monitorizat).",
    "Acd call rejected. An acd call is presented to a pilot, no resource is available to handle the call (See Note 1).": "Apel ACD respins. Un apel ACD este prezentat unui pilot, nu există resurse disponibile pentru a gestiona apelul (Vezi Nota 1).",
    "The call was ringing an agent, an other agent of the same processing group picked?up the call.": "Apelul suna un agent, alt agent din același grup de procesare a preluat apelul.",
    "An acd call is distributed on a resource which is not an agent.": "Un apel ACD este distribuit către o resursă care nu este un agent.",
    "An acd call is picked?up by a non assigned agent (the call becomes a non?acd call).": "Un apel ACD este preluat de către un agent neasignat (apelul devine un apel non-ACD).",
    "An acd_call was transferred to a non assigned agent, the call becomes a non acd call": "Un apel ACD a fost transferat către un agent neasignat, apelul devine un apel non-ACD",
    "A call was released due to the agent's unplugged phone set": "Un apel a fost încheiat din cauza scosului setului de telefon al agentului",
    "A call connected to an IVR while waiting in queue (IVR in queue feature) was extracted from the queue by the IVR.": "Un apel conectat la un IVR în timpul așteptării în coadă (funcția IVR în coadă) a fost extras din coadă de către IVR.",
    "For an ACR call, a selection mode or an attribute list (skill, expert, character) change happened in waiting room.": "Pentru un apel ACR, în sala de așteptare s-a produs o schimbare a modului de selecție sau a listei de atribute (abilități, expertiză, caracter).",
    "An ACR rotation happened, the new rang agent and the previous one belong to the same PG.": "A avut loc o rotație ACR, noul agent rangat și cel anterior aparțin aceluiași grup de procesare.",
    "An ACR rotation happened, the new rang agent and the previous one belong to two different PG.": "A avut loc o rotație ACR, noul agent rangat și cel anterior aparțin la două grupuri de procesare diferite.",
    "Call is suspended.": "Apelul este suspendat.",
    "Ringing rotation for an ACR call distributed to a dedicated pilot": "Rotația soneriei pentru un apel ACR distribuit către un pilot dedicat",
    "An ACR call sent to a dedicated pilot (ACR networking) is not served and comes back on local node.": "Un apel ACR trimis către un pilot dedicat (rețea ACR) nu este servit și se întoarce pe nodul local.",
    "An enquiry call is done towards an agent. When the caller releases this enquiry call by a transfert, communication ticket for th": "Se face un apel de informare către un agent. Când apelantul încheie acest apel de informare printr-un transfer, biletul de comunicare pentru th",
    "s": "s",
    "h": "h",
    "Speech to text playground": "Platforma conversie vorbire-text",
    "Project ID": "ID proiect",
    "Project name": "Nume proiect",
    "Calls count": "Număr de apeluri",
    "Calls with transcription": "Apeluri cu transcriere",
    "Transcriptions count": "Număr de transcrieri",
    "Transcription time": "Timp de transcriere",
    "Top 10 projects statistics": "Statistici top 10 proiecte",
    "Project": "Proiect",
    "Pilot": "Pilot",
    "Access code": "Cod de acces",
    "Mars ID": "ID Marte",
    "Total statistics": "Statistici totale",
    "Transcription": "Transcriere",
    "Copied to clipboard": "Copiat în clipboard",
    "Here it will be ability to create a transcription with custom parameters": "Aici va fi posibilitatea de a crea o transcriere cu parametri personalizați",
    "transcription details": "detalii despre transcriere",
    "Show": "Afișează",
    "Show full transcription": "Afișează transcrierea completă",
    "Show linked to timecode": "Afișează legat de codul de timp",
    "Transcription date": "Data transcrierii",
    "Transcription execution time": "Timpul de execuție al transcrierii",
    "Model": "Model",
    "Language": "Limba",
    "Condition on previous text": "Condiție pe textul anterior",
    "Temperature": "Temperatură",
    "Word timestamps": "Marcaje temporale ale cuvintelor",
    "Initial prompt": "Mesaj inițial",
    "Max initial timestamp": "Marcaj temporal inițial maxim",
    "No speech threshold": "Prag fără vorbire",
    "Agent CCA": "CCA agenta",
    "Call theme": "Tematică apel",
    "Call sub theme": "Subtematică apel",
    "Call details": "Detalii apel",
    "Calls without transcription in queue": "Apeluri fără transcriere în coadă",
    "Duration without transcription in queue": "Durata fără transcriere în coadă",
    "Profile": "Profil",
    "Settings": "Setări",
    "Logout": "Deconectare",
    "Wait for timestamp with transcription": "Așteptați marcajul temporal cu transcriere",
    "There is no available transcription": "Nu există transcriere disponibilă",
    "Projects statistics": "Statistici ale proiectelor",
    "Show less": "Afișează mai puțin",
    "Show more": "Afișează mai mult",
    "With transcription": "Cu transcriere",
    "Hide additional filters": "Ascundeți filtrele suplimentare",
    "Show additional filters": "Afișați filtrele suplimentare",
    "Send request to get access": "Trimiteți cerere pentru a obține accesul",
    "Issue": "Problemă",
    "Errors": "Erori",
    "New ai chat": "Chat AI nou",
    "Cancel": "Anulează",
    "Save": "Salvează",
    "AI Chat": "Chat AI",
    "Add new chat": "Adaugă chat nou",
    "Send message": "Trimite mesaj",
    "Delete this chat?": "Ștergeți acest chat?",
    "Type your message here...": "Tastați mesajul dvs. aici...",
    "Create new chat?": "Creați chat nou?",
    "Chat name": "Nume chat",
    "AI playground": "Platforma AI",
    "Invalid username or password": "Nume de utilizator sau parolă invalidă",
    "IVR time": "Timp IVR",
    "Wait time": "Timp de așteptare",
    "System context": "Context de sistem",
    "Answer settings": "Setări de răspuns",
    "video": "video",
    "File Name": "Nume fișier",
    "Upload date": "Data încărcării",
    "Open": "Deschide",
    "Transcribe": "Transcrie",
    "Actions": "Acțiuni",
    "Sure to delete?": "Sigur doriți să ștergeți?",
    "Click or drag audio file to this area to upload": "Faceți clic sau trageți fișierul audio în această zonă pentru a încărca",
    "wav or mp3 file are supported": "sunt acceptate fișierele wav sau mp3",
    "New ai chat (GPT 4 engine)": "Chat AI nou (GPT 4)",
    "Unknown": "Necunoscut",
    "Select a Project": "Selectați un Proiect",
    "Temperature parameter determines the level of creativity and randomness of text generation. If you want stable and predictable results, use values in the range of 0.1 - 0.5. For experimental or creative tasks, you can try 0.7 - 1.0.": "Parametrul de temperatură determină nivelul de creativitate și aleatoriu al generării textului. Dacă doriți rezultate stabile și previzibile, utilizați valori în intervalul 0.1 - 0.5. Pentru sarcini experimentale sau creative, puteți încerca 0.7 - 1.0.",
    "The Language parameter sets the language that the model will use for recognition or transliteration. Choosing the correct language improves the accuracy of the results.": "Parametrul de limbă setează limba pe care modelul o va utiliza pentru recunoaștere sau transliterare. Alegerea limbii corecte îmbunătățește acuratețea rezultatelor.",
    "Select language": "Selectați limba",
    "Automatic": "Automat",
    "Hallucination Silence Threshold": "Prag de Liniște pentru Halucinații",
    "The Hallucination Silence Threshold parameter determines the duration of silence that the system interprets as a hallucination. Low values (0-2): Quick response to pauses, suitable for speech without long pauses. High values (5-60): For audio with significant pauses to avoid false interpretation of silence.": "Parametrul Prag de Liniște pentru Halucinații determină durata tăcerii pe care sistemul o interpretează ca halucinație. Valori mici (0-2): Răspuns rapid la pauze, potrivit pentru vorbire fără pauze lungi. Valori mari (5-60): Pentru audio cu pauze semnificative pentru a evita interpretarea falsă a tăcerii.",
    "Initial Prompt sets the initial text or question for the model. Use it to control the context or direction of the response. This helps shape responses according to expectations.": "Promptul inițial setează textul sau întrebarea inițială pentru model. Utilizați-l pentru a controla contextul sau direcția răspunsului. Acest lucru ajută la modelarea răspunsurilor conform așteptărilor.",
    "Suppress Tokens": "Suprimare Tokeni",
    "Suppress Tokens is a parameter that allows you to exclude certain tokens (words or characters) from the results of the model generation. It is used to avoid unwanted words or phrases in the responses.": "Suprimarea Tokenilor este un parametru care vă permite să excludeți anumiți tokeni (cuvinte sau caractere) din rezultatele generării modelului. Este utilizat pentru a evita cuvinte sau fraze nedorite în răspunsuri.",
    "Hotwords": "Cuvinte Cheie",
    "Hotwords is a set of words or phrases that receive higher priority during processing of audio or text. This allows the system to better recognize or highlight specific key words.": "Cuvintele Cheie sunt un set de cuvinte sau fraze care primesc prioritate mai mare în timpul procesării audio sau text. Acest lucru permite sistemului să recunoască sau să evidențieze mai bine cuvintele cheie specifice.",
    "Word Timestamps allow you to get the exact time of each word in the audio file. This is useful for synchronizing subtitles or other applications that require tracking the time of words.": "Marcajele temporale ale cuvintelor vă permit să obțineți timpul exact al fiecărui cuvânt din fișierul audio. Acest lucru este util pentru sincronizarea subtitrărilor sau alte aplicații care necesită urmărirea timpului cuvintelor.",
    "Settings saved successfully": "Setările au fost salvate cu succes",
    "Error saving settings": "Eroare la salvarea setărilor",
    "QA": "QA",
    "QA Settings Coming Soon": "Setările QA vor fi disponibile în curând",
    "End cause": "Motivul încheierii apelului"
};

export { en, ua, ro };