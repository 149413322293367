// localStorage.js
export const saveState = (key, state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch (error) {
      console.error('Error saving state to local storage:', error);
    }
  };
  
  export const loadState = (key) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      console.error('Error loading state from local storage:', error);
      return undefined;
    }
  };
  
  export const removeState = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing state from local storage:', error);
    }
  };
  
  export const loadTicketsState = (key) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      console.error('Error loading state from local storage:', error);
      return undefined;
    }
  };
  
  export const saveTicketsState = (key, state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch (error) {
      console.error('Error saving state to local storage:', error);
    }
  };
  
  export const removeTicketsState = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing state from local storage:', error);
    }
  };
  



// // cookies-localStorage.js

// // Cookie helpers
// const setCookie = (name, value, days = 365) => {
//   const date = new Date();
//   date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//   const expires = `expires=${date.toUTCString()}`;
//   document.cookie = `${name}=${value};${expires};path=/`;
// };

// const getCookie = (name) => {
//   const nameEQ = `${name}=`;
//   const ca = document.cookie.split(';');
//   for (let i = 0; i < ca.length; i++) {
//       let c = ca[i];
//       while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//       if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// };

// // Store methods
// export const saveState = (key, state) => {
//   try {
//       if (key === 'access' || key === 'refresh') {
//           setCookie(key, state, 1); // Storing tokens for 1 day as an example
//       } else {
//           const serializedState = JSON.stringify(state);
//           localStorage.setItem(key, serializedState);
//       }
//   } catch (error) {
//       console.error(`Error saving state to ${key === 'access' || key === 'refresh' ? 'cookies' : 'local storage'}:`, error);
//   }
// };

// export const loadState = (key) => {
//   try {
//       if (key === 'access' || key === 'refresh') {
//           const token = getCookie(key);
//           return token === null ? undefined : token;
//       } else {
//           const serializedState = localStorage.getItem(key);
//           if (serializedState === null) {
//               return undefined;
//           }
//           return JSON.parse(serializedState);
//       }
//   } catch (error) {
//       console.error(`Error loading state from ${key === 'access' || key === 'refresh' ? 'cookies' : 'local storage'}:`, error);
//       return undefined;
//   }
// };

// export const removeState = (key) => {
//   try {
//       if (key === 'access' || key === 'refresh') {
//           setCookie(key, '', -1); // Deleting the cookie by setting a past expiration date
//       } else {
//           localStorage.removeItem(key);
//       }
//   } catch (error) {
//       console.error(`Error removing state from ${key === 'access' || key === 'refresh' ? 'cookies' : 'local storage'}:`, error);
//   }
// };

// // If the other methods for tickets are the same as the ones above, we don't need to duplicate them. 
// // If you have other specific operations you want to perform with the ticket-related methods, you can adapt them similarly.
