import React, {useEffect, useState} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { loadState } from '../../utils/localStorage';

import { useGetStatisticsQuery } from '../../utils/api/audioApi';
import { Row, Col, Statistic, Space, Table, Spin, Typography, Button, Popover } from 'antd';
import { SyncOutlined, LoadingOutlined, InfoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { secondsToTimeFormat } from '../../utils/helpers/dateTime';
import numbro from "numbro";
import dayjs from 'dayjs';
// import { 
//     useGetGpuStatsQuery,
// } from '../../utils/api/statsApi'; 

import './HomePage.css'

const { Title } = Typography;

const HomePage = () => {
  const auth = loadState('auth');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ showMore, setShowMore ] = useState(false);
  const { data, error, isLoading, isSuccess, refetch } = useGetStatisticsQuery();
  
  useEffect(() => {
    console.log(auth?.username);
    if (auth?.username === 'demo1') {
        navigate('/demo_stt');
    }
  }, [])

  
//   const { data: gpuStats, error: gpuStatsError, isLoading: gpuStatsIsLoading, isSuccess: gpuStatsIsSuccess } = useGetGpuStatsQuery();
    
  

  const handleReloadStatistics = () => {
        refetch();
    }
  const columns = [
        {
            title: t("Project ID"),
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return record.id
            },
            width: '70px'
        },
        {
            title: t("Project name"),
            dataIndex: 'project',
            key: 'project',
            render: (text, record) => {
                return (
                    <Button 
                      type='link' 
                      onClick={() => {
                        navigate(`/stt`, { state: { projectId: record.id } });
                      }}
                    >
                      {record.project}
                    </Button>
                  );
            },
            width: '100px'
        },
        {
            title: t("Start date"),
            dataIndex: 'db',
            key: 'db',
            render: (text, record) => {
                return dayjs(record.db).format('YYYY-MM-DD  HH:mm:ss')
            },
            width: '200px'
        },
        {
            title: t("End date"),
            dataIndex: 'de',
            key: 'de',
            render: (text, record) => {
                return dayjs(record.de).format('YYYY-MM-DD  HH:mm:ss')
            },
            width: '200px'
        },
        {
            title: t("Calls count"),
            dataIndex: 'calls_cnt',
            key: 'calls_cnt',
            render: (text, record) => {
                return numbro(record.calls_cnt).format({thousandSeparated: true})
            }
        },
        {
            title: t("Calls with transcription"),
            dataIndex: 'have_transcription',
            key: 'have_transcription',
            render: (text, record) => {
                return numbro(record.have_transcription).format({thousandSeparated: true})
            },
            width: '60px'
        },
        // {
        //     title: t("Transcriptions count"),
        //     dataIndex: 'transcription_cnt',
        //     key: 'transcription_cnt',
        //     render: (text, record) => {
        //         return numbro(record.transcription_cnt).format({thousandSeparated: true})
        //     }
        // },
        {
            title: t("Duration"),
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                return `${numbro(record.duration/60/60).format({thousandSeparated: true, mantissa: 0})} ${t("h")}`
            }
        },
        {
            title: t("Talk"),
            dataIndex: 'talk_time',
            key: 'talk_time',
            render: (text, record) => {
                return `${numbro(record.talk_time/60/60).format({thousandSeparated: true, mantissa: 0})} ${t("h")}`
            }
        },
        {
            title: t("Transcription time"),
            dataIndex: 'transcription_time',
            key: 'transcription_time',
            render: (text, record) => {
                return `${numbro(record.transcription_time/60/60).format({thousandSeparated: true, mantissa: 0})} ${t("h")}`
            }
        },
        {
            title: t("Calls without transcription in queue"),
            dataIndex: 'calls_cnt_not_transcribed',
            key: 'calls_cnt_not_transcribed',
            render: (text, record) => {
                return <div style={{color:"#cf1322"}}>{numbro(record.calls_cnt_not_transcribed-record.error_cnt).format({thousandSeparated: true})}</div>
            },
            width: '160px'
        },
        {
            title: t("Duration without transcription in queue"),
            dataIndex: 'duration_not_transcribed',
            key: 'duration_not_transcribed',
            render: (text, record) => {
                return <div style={{color:"#cf1322"}}>{numbro((record.duration_not_transcribed-record.error_duration)/60/60).format({thousandSeparated: true, mantissa: 2})} {t("h")}</div>
            },
            width: '160px'
            
        },
        {
            title: t("Errors"),
            dataIndex: 'error_cnt',
            key: 'error_cnt',
            render: (text, record) => {
                return <div style={{color:"#cf1322"}}>{numbro(record.error_cnt).format({thousandSeparated: true})}</div>
            },
            width: '140px'
            
        },

    ]
  return (
    <>
        <Row gutter={24} className='total-title-row'>
            <Space size={"large"} wrap>
            {/* <Popover 
                trigger="click"
                arrow={false}
                content={
                    gpuStatsIsSuccess &&
                    gpuStats?.data?.gpus?.map((gpu, index) => {
                        return (    
                            <Row gutter={24} style={{minWidth:'800px'}} key={index}>
                                <Col span={2}>
                                    <Statistic title={t("GPU")} value={gpu?.id} />
                                </Col>
                                <Col span={3}>
                                    <Statistic title={t("GPU Util")} value={gpu?.gpu_util} />
                                </Col>
                                <Col span={3}>
                                    <Statistic title={t("Mem Util")} value={gpu?.memory_util} />
                                </Col>
                                <Col span={4}>
                                    <Statistic title={t("GPU Temp")} value={gpu?.gpu_temp} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title={t("Power Used")} value={gpu?.power_draw} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title={t("Mem Used")} value={gpu?.used_mem} />
                                </Col>
                            </Row>
                            )
                        }
                        )
                    }
            >
                <InfoOutlined />
            </Popover> */}
            <Title level={3} onClick={handleReloadStatistics}>
                <a>{t("Total statistics")}</a>  
                <Button 
                        type="link" 
                        onClick={handleReloadStatistics} 
                        icon={!isLoading ? <SyncOutlined /> : <LoadingOutlined />}
                />
            </Title>
            </Space>
        </Row>
        <Row gutter={24} className='summary-stat-row'>
            <Space direction='horizontal' size={24}>
                {!isLoading && isSuccess ?
                <>
                    <Statistic title={t("Calls count")} value={data?.summary?.calls_cnt} groupSeparator={","}/>
                    <Statistic title={t("Calls with transcription")} value={data?.summary?.have_transcription} groupSeparator={","}/>
                    <Statistic title={t("Transcriptions count")}  value={data?.summary?.transcription_cnt - data?.summary?.error_cnt} groupSeparator={","}/>
                    <Statistic title={t("Duration")}  value={data?.summary?.duration/60/60} groupSeparator={","} precision={2} suffix={t("h")}/>
                    <Statistic title={t("Talk")}  value={data?.summary?.talk_time/60/60} groupSeparator={","} precision={2} suffix={t("h")}/>
                    <Statistic title={t("Transcription time")}  value={data?.summary?.transcription_time/60/60} groupSeparator={","} precision={2} suffix={t("h")}/>
                    <Statistic title={t("Calls without transcription in queue")} value={data?.summary?.calls_cnt_not_transcribed - data?.summary?.error_cnt} groupSeparator={","} valueStyle={{color: "#cf1322"}}/>
                    <Statistic title={t("Duration without transcription in queue")}  value={(data?.summary?.duration_not_transcribed - data?.summary?.error_duration)/60/60} groupSeparator={","} precision={2} suffix={t("h")} valueStyle={{color: "#cf1322"}}/>
                    <Statistic title={t("Errors")}  value={data?.summary?.error_cnt} groupSeparator={","} precision={0} valueStyle={{color: "#ff0051"}}/>
                </>
                : 
                    <Spin size="large" />
                }
            </Space>
        </Row>
        <Row gutter={24} className='detailed-title-row'>
            <Title level={3}>{t("Projects statistics")}</Title>
        </Row>
        <Row gutter={24} className='detailed-stat-row'>
            <Table
                className="detailed-stat-table "
                size='small'
                dataSource={showMore ? data?.detailed : data?.detailed.slice(0, 10)}
                columns={columns}
                rowKey={record => record.id}
                pagination={false}
                loading={isLoading}
            >

            </Table>
        </Row>
        <Row gutter={24} className='show-more-row'>
            <Button className="show-more-button" type='ghost' onClick={() => setShowMore(!showMore)}>{showMore ? t("Show less") : t("Show more")}</Button>
        </Row>
    </>
  )
}

export default HomePage