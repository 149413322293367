import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Spin, Row, Col, Button, Form, Input, Typography, Tooltip, Pagination, Select, Card, Space, message, Slider, Switch, Tabs } from 'antd';
import { SettingOutlined, SearchOutlined, FieldNumberOutlined, SaveOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useGetProjectsSettingsQuery, useSetProjectTranscriptionSettingsMutation } from '../../utils/api/audioApi';
import './ProjectsSettings.css';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveProjectId } from '../../utils/store';

const { Title, Text } = Typography;

const tempMarks = { 0.0: '0', 0.1: '0.1', 0.2: '0.2', 0.3: '0.3', 0.4: '0.4', 0.5: '0.5', 0.6: '0.6', 0.7: '0.7', 0.8: '0.8', 0.9: '0.9', 1.0: '1' };
const halucinationMarks = { 0: '0', 10: '10', 20: '20', 30: '30', 40: '40', 50: '50', 60: '60' };

const ProjectsSettingsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activeProjectId = useSelector((state) => state.projectSettings.activeProjectId);
    const [form] = Form.useForm();
    const { data: projectsSettingsData, isFetching: isFetchingProjectsSettings } = useGetProjectsSettingsQuery();
    const [setProjectTranscriptionSettings, { isFetching: isFetchingSetProjectTranscriptionSettings }] = useSetProjectTranscriptionSettingsMutation();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [hasFormChanged, setHasFormChanged] = useState(false);

    const activeProjectSettings = projectsSettingsData?.find(
        item => item.id === activeProjectId
    )?.transcribe_settings || {};

    const handleSelectProject = useCallback((projectId) => {
        dispatch(setActiveProjectId(projectId));
    }, [dispatch]);

    useEffect(() => {
        if (activeProjectId && activeProjectSettings) {
            form.setFieldsValue({
                temperature: activeProjectSettings.temperature,
                language: activeProjectSettings.language,
                hallucination_silence_threshold: activeProjectSettings.hallucination_silence_threshold,
                initial_prompt: activeProjectSettings.initial_prompt,
                suppress_tokens: activeProjectSettings.suppress_tokens,
                hotwords: activeProjectSettings.hotwords,
                word_timestamps: activeProjectSettings.word_timestamps,
            });
            setHasFormChanged(false);
        } else {
            form.resetFields();
        }
    }, [activeProjectId, activeProjectSettings, form]);

    const handleFormChange = useCallback(() => {
        const currentValues = form.getFieldsValue();
        const hasChanged = Object.keys(currentValues).some(key => {
            if (currentValues[key] === undefined && !activeProjectSettings[key]) {
                return false;
            }
            if (typeof currentValues[key] === 'boolean') {
                return currentValues[key] !== !!activeProjectSettings[key];
            }
            return currentValues[key] !== activeProjectSettings[key];
        });
        
        setHasFormChanged(hasChanged);
    }, [form, activeProjectSettings]);

    const handleSetProjectTranscriptionSettings = async (values) => {
        try {
            await setProjectTranscriptionSettings({ project_id: activeProjectId, settings: values }).unwrap().then(() => {
                // refetchProjectsSettings();
                message.success(t('Settings saved successfully'));
            });
        } catch {
            message.error(t('Error saving settings'));
        }
    };

    const filteredData = projectsSettingsData?.filter(item =>
        item.project?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.projid.toString().toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <Row className="projects-settings-main-content-row">
            <Col span={8} className="left-menu">
                <Input
                    prefix={<SearchOutlined />}
                    placeholder={t("Search")}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{ marginBottom: 12 }}
                />
                {isFetchingProjectsSettings ? <Spin size='large' style={{ width: '100%', height: '100%' }} /> : (
                <List
                    size="small"
                    loading={isFetchingProjectsSettings}
                    dataSource={paginatedData}
                    renderItem={item => (
                        <List.Item
                            className="list-custom"
                            style={{ backgroundColor: item.id === activeProjectId ? '#c9daf0' : 'transparent' }}
                            key={item.projid}
                            onClick={() => handleSelectProject(item.id)}
                        >
                            <List.Item.Meta 
                                title={item.project} 
                                description={(
                                    <>
                                        <Row>
                                            {/* <Space> */}
                                                <Col span={6}>
                                                    <FieldNumberOutlined style={{ marginRight: 4 }} />
                                                    <Text type='primary' strong>{item?.projid || t('Unknown')}</Text>
                                                </Col>
                                                <Col span={8}>
                                                    <UserOutlined style={{ marginRight: 4 }} />
                                                    {item?.transcribe_settings?.last_updated_by?.username || t('Unknown')}
                                                </Col>
                                                <Col span={10}>
                                                    <ClockCircleOutlined style={{ marginRight: 4, marginLeft: 8 }} />
                                                    {item?.transcribe_settings?.last_updated_at ? moment(item.transcribe_settings.last_updated_at).format('YYYY-MM-DD HH:mm:ss') : t('Unknown')}
                                                </Col>
                                            {/* </Space> */}
                                        </Row>
                                    </>
                                )}
                            />
                        </List.Item>
                    )}
                />
                )}
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={filteredData.length}
                    onChange={(page, newPageSize) => { setCurrentPage(page); setPageSize(newPageSize || pageSize); }}
                    showSizeChanger
                    pageSizeOptions={['5', '10', '15', '20']}
                    style={{ textAlign: 'center', marginTop: 10 }}
                />
            </Col>

            <Col span={16} className="right-content">
                <Title level={4} className='custom-title'>
                    {/* <Space> */}
                        {projectsSettingsData?.find(item => item.id === activeProjectId)?.project || t('Select a Project')}
                    {/* </Space> */}
                </Title>
                    {activeProjectId && (
                        <Tabs className="settings-tabs" type="card">
                            <Tabs.TabPane tab={t('Transcription')} key="1">
                                <div className="tab-content-scroll">
                                    <Form
                                        form={form}
                                        name='set-project-transcription-settings'
                                        className='set-project-transcription-settings'
                                        onFinish={handleSetProjectTranscriptionSettings}
                                        layout='vertical'
                                        onValuesChange={handleFormChange}
                                    >
                                        <Form.Item name='temperature' label={t('Temperature')} tooltip={t('Temperature parameter determines the level of creativity and randomness of text generation. If you want stable and predictable results, use values in the range of 0.1 - 0.5. For experimental or creative tasks, you can try 0.7 - 1.0.')}>
                                            <Slider marks={tempMarks} min={0} max={1} step={0.1} />
                                        </Form.Item>
                                        <Form.Item name='language' label={t('Language')} tooltip={t('The Language parameter sets the language that the model will use for recognition or transliteration. Choosing the correct language improves the accuracy of the results.')}>
                                            <Select placeholder={t("Select language")}>
                                                <Select.Option value="uk">{t('Ukrainian')}</Select.Option>
                                                <Select.Option value="en">{t('English')}</Select.Option>
                                                <Select.Option value="hybrid">{t('Automatic')}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name='hallucination_silence_threshold' label={t('Hallucination Silence Threshold')} tooltip={t('The Hallucination Silence Threshold parameter determines the duration of silence that the system interprets as a hallucination. Low values (0-2): Quick response to pauses, suitable for speech without long pauses. High values (5-60): For audio with significant pauses to avoid false interpretation of silence.')}>
                                            <Slider marks={halucinationMarks} min={0} max={60} step={1} />
                                        </Form.Item>
                                        <Form.Item name='initial_prompt' label={t('Initial prompt')} tooltip={t('Initial Prompt sets the initial text or question for the model. Use it to control the context or direction of the response. This helps shape responses according to expectations.')}>
                                            <Input.TextArea autoSize />
                                        </Form.Item>
                                        <Form.Item name='suppress_tokens' label={t('Suppress tokens')} tooltip={t('Suppress Tokens is a parameter that allows you to exclude certain tokens (words or characters) from the results of the model generation. It is used to avoid unwanted words or phrases in the responses.')}>
                                            <Input.TextArea autoSize allowClear />
                                        </Form.Item>
                                        <Form.Item name='hotwords' label={t('Hotwords')} tooltip={t('Hotwords is a set of words or phrases that receive higher priority during processing of audio or text. This allows the system to better recognize or highlight specific key words.')}>
                                            <Input.TextArea autoSize allowClear />
                                        </Form.Item>
                                        <Form.Item name='word_timestamps' label={t('Word timestamps')} tooltip={t('Word Timestamps allow you to get the exact time of each word in the audio file. This is useful for synchronizing subtitles or other applications that require tracking the time of words.')}>
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button 
                                                type='primary' 
                                                htmlType='submit' 
                                                loading={isFetchingSetProjectTranscriptionSettings} 
                                                icon={<SaveOutlined />}
                                                disabled={!hasFormChanged}
                                            >
                                                {t('Save')}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('QA')} key="2">
                                <div className="tab-content-scroll">
                                    {t('QA Settings Coming Soon')}
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    )}
            </Col>
        </Row>
    );
};

export default ProjectsSettingsPage;