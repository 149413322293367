import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Space, Select, Avatar, Button, Popover, Typography, Radio, Tooltip, Row, Col, Dropdown, Checkbox, Badge } from 'antd';
import { TranscriptionViewer } from '../TranscriptionViewer';
import { GB, UA, RO } from 'country-flag-icons/react/3x2'
import { loadState } from '../../utils/localStorage';
import { useLanguage } from '../../utils/hooks/useLanguage';
import { ExceptionOutlined, MessageOutlined, UserOutlined, BulbOutlined, LogoutOutlined, SettingOutlined, GlobalOutlined, BellOutlined, SoundOutlined } from '@ant-design/icons';
import enUS from 'antd/locale/en_US';
import ukUA from 'antd/locale/uk_UA';
import roRO from 'antd/locale/ro_RO';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { MainRouter } from '../MainRouter';
import { logout } from '../../utils/reducers/authReducer';
import { useDispatch } from 'react-redux';


import './MainLayout.css';
import LogoutButton from './LogoutButton';
import { ProjectsSettingsPage } from '../ProjectsSettingsPage';

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;



const MainLayout = () => {
  const auth = loadState('auth');
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);
  const navigate = useNavigate();
  const [renderKey, setRenderKey] = useState(Date.now());
  const [language, updateLanguage] = useLanguage();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      dispatch(logout());
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  useEffect(() => {
    const storedLanguage = auth?.language;
    if (storedLanguage) {
      updateLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    updateLanguage(lang);
    setRenderKey(Date.now());
  };

  const menuitems = [
    { key: '/stt', label: t('STT'), icon: <SoundOutlined /> },
    { key: '/live_stt', label: t('Live STT'), icon: <SoundOutlined /> },
    { key: '/live_suggestions', label: t('Live Suggestions'), icon: <BulbOutlined /> },
    { key: '/aichat', label: t('AiChat'), icon: <MessageOutlined /> },
    { key: '/demo_stt', label: t('Demo STT'), icon: <SoundOutlined /> },
    { key: '/demo_aichat', label: t('Demo AiChat'), icon: <MessageOutlined /> },
    { key: '/projects_settings', label: t('Projects Settings'), icon: <SettingOutlined /> },
  ];

  const menuitems_demo = [
    { key: '/demo_stt', label: t('Demo STT'), icon: <SoundOutlined /> },
    { key: '/demo_aichat', label: t('Demo AiChat'), icon: <MessageOutlined /> },
    { key: '/live_suggestions', label: t('Live Suggestions'), icon: <BulbOutlined /> },
  ];
  
  const dropdownUserMenu = [
    { 
      key: '/profile', 
      label: (<a><Text type='primary'>{t('Profile')}</Text></a>), 
      icon: <UserOutlined />,
      onClick: () => navigate('/profile'),
      disabled: true,
    },
    { 
      key: '/settings', 
      label: (<a><Text type='primary'>{t('Settings')}</Text></a>), 
      icon: <SettingOutlined />,
      disabled: true,
    },
    { 
      key: '/logout', 
      label: (<a><Text type='primary'>{t('Logout')}</Text></a>), 
      icon: <LogoutOutlined /> ,
      danger: true,
      onClick: handleLogout,
    },
  ]
  const dropdownLanguageMenu = [
    { 
      key: '/profile', 
      label: (
        <Radio.Group onChange={(v)=>{changeLanguage(v.target.value)}} defaultValue={auth?.language} >
          <Space direction="vertical">
              <Tooltip className="main-layout-language-radio" title={t('English')}><Radio  value="en"><GB className="flag-icon"/> {t('English')}</Radio></Tooltip>
              <Tooltip className="main-layout-language-radio" title={t('Українська')}><Radio value="ua"><UA className="flag-icon"/> {t('Українська')}</Radio></Tooltip>
              <Tooltip className="main-layout-language-radio" title={t('Română')}><Radio value="ro"><RO className="flag-icon"/> {t('Română')}</Radio></Tooltip>
          </Space>
        </Radio.Group>
      ), 
    },
  ]

  const mesages = [
    {key: '1', title: 'You have 1 new message', description: '1 minute ago'},
    {key: '2', title: 'You have 2 new messages', description: '2 minutes ago'},
    {key: '3', title: 'You have 3 new messages', description: '3 minutes ago'},
    {key: '4', title: 'You have 4 new messages', description: '4 minutes ago'},
  ]

  return (
    <>
    <ConfigProvider locale={language === 'en' ? enUS : ukUA} localeAntd={language === 'en' ? enUS : ukUA}>
      <Layout key={renderKey} className="main-layout-layout">

      <Header className="main-layout-header">
        <Row gutter={24} className="main-layout-header-row">
          <Col span={18} className="main-layout-header-left-col">
            <Row gutter={24} className="main-layout-left-group">
              <Col span={2} className="main-layout-logo-col">
                <Link className="main-layout-logo-col-a" to="/">
                  <img src={`${process.env.PUBLIC_URL}/adelina_favicon-150x150.png`} className="logo" />
                </Link>
              </Col>
              <Col span={22} className="main-layout-menu-col">
                <Menu
                  onClick={onClick}
                  theme="dark"
                  mode="horizontal"
                  className="main-layout-menu"
                  selectedKeys={[current]}
                  items={auth?.username === "demo1" ? menuitems_demo : menuitems}
                />          
              </Col>
            </Row>
          </Col>
          <Col span={6} className="main-layout-header-right-col">
            <Row className="main-layout-right-group">
            {/* <Dropdown
                className="main-layout-dropdown"
                menu={{items: dropdownLanguageMenu}}
                placement="bottomLeft"
                trigger={['click']}
                disabled
              >
                <div className="hoverable-div">
                  <Space direction='horizontal' className="main-layout-language-icon-space">
                    <Badge size="small" count={mesages?.length} className="main-layout-language-icon">
                      <BellOutlined  />
                    </Badge>
                  </Space>
                </div>
              </Dropdown> */}
              <Dropdown
                className="main-layout-dropdown"
                menu={{items: dropdownLanguageMenu}}
                placement="bottomLeft"
                trigger={['click']}
              >
                <div className="hoverable-div">
                  <Space direction='horizontal' className="main-layout-language-icon-space">
                    {/* <GlobalOutlined className="main-layout-language-icon" /> */}
                    {
                      language === 'en' ? <GB className="flag-icon"/> :
                      language === 'ua' ? <UA className="flag-icon"/> :
                      language === 'ro' ? <RO className="flag-icon"/> : null
                    }
                  </Space>
                </div>
              </Dropdown>
              <Dropdown 
                className="main-layout-dropdown"
                menu={{items: dropdownUserMenu}}
                placement="bottomLeft"
                trigger={['click']}
              >
                <div className="hoverable-div">
                  <Space direction='horizontal'>
                    <Avatar 
                      className="main-layout-avatar" 
                      // style={{backgroundColor: "#1677FF"}} 
                      size="large"
                      >
                        {auth?.username?.[0]?.toUpperCase()}
                    </Avatar>
                    <a style={{color:"white"}}>{auth?.username}</a>
                  </Space>
                </div>
              </Dropdown>
            </Row> 
          </Col>
        </Row>
      </Header>
        <Content className="main-layout-content">
          <MainRouter />
        </Content>
        <Footer className="main-layout-footer">
          <Text type="secondary">© 2023 Adelina</Text>
        </Footer>
      </Layout>
    </ConfigProvider>
    </>
  );
};

export default MainLayout;
