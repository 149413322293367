import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Menu, Space, Select, Avatar, Button, Popover, Typography, Radio, Tooltip, Row, Col, Card, Modal, List, Switch, Empty, Tabs, Divider, message } from 'antd';
import { DislikeOutlined, LikeOutlined, FileTextOutlined, ReadOutlined, DownloadOutlined, DownOutlined, UpOutlined, PlusOutlined } from '@ant-design/icons';
import { AudioPlayer } from '../AudioPlayer';
import ReactPlayer from 'react-player'
import { useGetAudioRecordTranscriptionQuery, useGetAudioRecordTranscriptionsQuery } from '../../utils/api/audioApi';
import { useCreateQaChatMutation, useGetQaChatQuery, useSendQaWebSocketMessageMutation } from '../../utils/api/qaChatApi';
import { secondsToTimeFormat } from '../../utils/helpers/dateTime';
import { useTranslation } from 'react-i18next';
import './AudioPage.css'
import dayjs from 'dayjs';
import { loadState } from '../../utils/localStorage';
import { saveAs } from 'file-saver';
import { CO, RO } from 'country-flag-icons/react/1x1';


const BASE_AUDIO_URL = process.env.REACT_APP_BASE_AUDIO_URL;
const BASE_VIDEO_URL = process.env.REACT_APP_BASE_VIDEO_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const { Title, Text } = Typography;

const AudioPage = ({call_data, marsid, visible, onCancel }) => {
  const { t } = useTranslation()
  const [showButton, setShowButton] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentTranscriptionIndex, setCurrentTranscriptionIndex] = useState(0);
  const [currentTranscriptionId, setCurrentTranscriptionId] = useState();
  const [transcriptionDetailsVisible, setTranscriptionDetailsVisible] = useState(false);
  const [callDetailsVisible, setCallDetailsVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [startTime, setStartTime] = useState(0.0);
  const audioPlayerRef = useRef(null); 
  const trDataRef = useRef([]);
  const trDurationRef = useRef(0);
  const titleRef = useRef(null);
  
  const { data: transcriptionsData, isFetching: isFetchingTranscriptionsData, isSuccess: isSuccessTranscriptionsData, refetchTranscriptionsData } = useGetAudioRecordTranscriptionsQuery({
    callId: call_data?.id,
  });

  const { data: qaChatData, isFetching: isFetchingQaChatData, isSuccess: isSuccessQaChatData, refetchQaChatData } = useGetQaChatQuery(currentTranscriptionId, {skip: !currentTranscriptionId})
  const [createQaChat, { isLoading: createQaChatIsLoading }] = useCreateQaChatMutation();


  useEffect(() => {
    if(isSuccessTranscriptionsData && !isFetchingTranscriptionsData && transcriptionsData && transcriptionsData?.transcriptions?.length > 0){
      setCurrentTranscriptionId(transcriptionsData?.transcriptions?.[currentTranscriptionIndex]?.id);
      trDataRef.current = transcriptionsData?.transcriptions?.[currentTranscriptionIndex]?.transcription_result?.map((item, index) => {
        return {
          ...item,
          timecode: `${secondsToTimeFormat(item.start)} - ${secondsToTimeFormat(item.end)}`,
          className: "list-item"
        }
      });

      const fData = trDataRef.current?.filter(item => parseInt(item.start) <= parseInt(trDurationRef.current));
          const revFdata = fData?.reverse().map((item, index) => {
            return {
              ...item,
              timecode: `${secondsToTimeFormat(item.start)} - ${secondsToTimeFormat(item.end)}`,
              className: parseInt(item.start)<=parseInt(trDurationRef.current) && parseInt(item.end)>=parseInt(trDurationRef.current) ? "list-item active-list-item" : item.className,
            }
          });
      setFilteredData(revFdata || []);

    }
  }, [transcriptionsData, currentTranscriptionIndex]);

  useEffect(() => {
    if (!visible) {
      if (audioPlayerRef.current) {
        audioPlayerRef.current.handleStop();
        audioPlayerRef.current.handleUnmount();
        audioPlayerRef.current = null;
      }
    }
  }, [visible]);

  useEffect(() => {
    if(call_data){
      setStartTime(1.0*(call_data.wait_time+call_data.ivr_time)/call_data.duration);
    }
  }, [call_data]);


  const handleCurrentDurationChange = (duration) => {

      if(parseInt(trDurationRef.current) !== parseInt(duration)){
        trDurationRef.current = parseInt(duration);
        if(trDataRef.current){
          const fData = trDataRef.current?.filter(item => parseInt(item.start) <= parseInt(duration));
          const revFdata = fData?.reverse().map((item, index) => {
            return {
              ...item,
              timecode: `${secondsToTimeFormat(item.start)} - ${secondsToTimeFormat(item.end)}`,
              className: parseInt(item.start)<=parseInt(duration) && parseInt(item.end)>=parseInt(duration) ? "list-item active-list-item" : item.className,
            }
          });
            setFilteredData(revFdata || []);
        }
      }
  };

  const transcriptionToText = (transcriptionId) => {
    const transcription = transcriptionsData?.transcriptions?.find(item => item?.id === transcriptionId)?.transcription_result;
    const txt = transcription?.map((item) => {
      return `${secondsToTimeFormat(item?.start)}-${secondsToTimeFormat(item?.end)} ${item?.speaker}:${item?.text}`
    }).join('\n');
    return txt;
  }

  const exportTranscription = (transcriptionId, save) => {
    if(!transcriptionId) return message.error(t('There is no available transcription'));
    const txt = transcriptionToText(transcriptionId);
    const blob = new Blob([txt], {type: "text/plain;charset=utf-8"});
    const url = URL.createObjectURL(blob);
    if(!save){saveAs(blob, `marsid-${marsid}_trid-${transcriptionId}.txt`)}
    if(save){return window.open(url, '_blank')}
  }

  const speakerColor = {
    '111': '#f56a00',
    'client': '#1677FE',
    'agent': '#ED6C03',
  }

  return (
    <>
      <Modal
        closable={false}
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={"90%"}
        styles={{ minHeight: '92vh', maxHeight: '92vh', overflowY: 'hidden', overflowX: "hidden" }}
        style={{ top: "20px" }}
      >
        <>
          <AudioPlayer
            ref={audioPlayerRef}
            id={marsid}
            // key={marsid}
            startTime={startTime}
            audioUrl={`${BASE_AUDIO_URL}${marsid}`}
            onError={(err) => { console.log(err); }}
            playPause={false}
            playAfterLoad={true}
            onIsPlayingChange={() => { }}
            onLoadingChange={() => { }}
            onCurrentDurationChange={handleCurrentDurationChange}
            token={loadState('auth').access}
            modalIsVisible={visible}
          />
          <Row>
            {videoVisible && <ReactPlayer forceHLS={true} url={`${BASE_VIDEO_URL}${marsid}`} controls={true} width='100%' />}
            {call_data?.video_filepath &&
              <Divider style={{margin:'2px'}}>
                  <Button 
                    onClick={() => setVideoVisible(!videoVisible)}
                    style={{fontSize:'12px'}} 
                    icon={videoVisible ? <UpOutlined /> : <DownOutlined />}
                    type='link'>
                      {`${videoVisible ? t('Hide') : t('Show')} ${t("video")}`}
                  </Button>
                </Divider>
              }
          </Row>
          <Row>
            <Col span={12} style={{paddingRight: "16px"}} >
              <Space align='baseline' direction='horizontal' style={{ textJustify: 'center' }}>
              <Title 
                copyable={{text: `${BASE_URL}/stt/audiorecord/${marsid}`}} 
                level={4} 
                >
                  {t("Transcription")}: {currentTranscriptionId}
              </Title>
              <Button icon={<DownloadOutlined />} 
                onClick={() => exportTranscription(currentTranscriptionId, false)}
                type='primary'
                size='small'
              />
              <Button icon={<FileTextOutlined />} 
                onClick={() => exportTranscription(currentTranscriptionId, true)}
                type='primary'
                size='small'
              />
              <Switch size='small' onChange={setShowButton} unCheckedChildren={t("Show full transcription")} checkedChildren={t("Show linked to timecode")}></Switch>
              
              </Space>
              {/* <Tabs
                style={{margin:'2px'}}
                size='small'
                centered={true}
                defaultActiveKey="0"
                items={transcriptionsData?.transcriptions?.map((item, index) => {
                  return {
                    label: `Tr-${item.id}`,
                    key: index,
                  };
                })}
                onChange={(key) => {
                  setCurrentTranscriptionIndex(key);
                }}
                tabBarExtraContent={
                  <Button
                    onClick={() => {
                      message.info(t('Here it will be ability to create a transcription with custom parameters'));
                    }}
                    type='default'
                    icon={<PlusOutlined />}
                    />
                }
              /> */}
              <Divider style={{margin:'2px'}}>
                <Button 
                  onClick={() => setTranscriptionDetailsVisible(!transcriptionDetailsVisible)}
                  style={{fontSize:'12px'}} 
                  icon={transcriptionDetailsVisible ? <UpOutlined /> : <DownOutlined />}
                  type='link'>
                    {`${transcriptionDetailsVisible ? t('Hide') : t('Show')} ${t("transcription details")}`}
                </Button>
              </Divider>
              {transcriptionDetailsVisible &&
                <>
                  <Row>
                    <Col span={5}>
                      <Text strong>{t("Transcription date")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{dayjs(transcriptionsData.transcriptions[currentTranscriptionIndex].transcription_date).format("YYYY-MM-DD HH:mm:ss")}</Text>
                    </Col>
                    <Col span={5}>
                      <Text strong>{t("Transcription execution time")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].task_exec_time}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <Text strong>{t("Model")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].model}</Text>
                    </Col>
                    <Col span={5}>
                      <Text strong>{t("Language")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].language}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <Text strong>{t("Condition on previous text")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].condition_on_previous_text.toString()}</Text>
                    </Col>
                    <Col span={5}>
                      <Text strong>{t("Temperature")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].temperature}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <Text strong>{t("Word timestamps")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].word_timestamps.toString()}</Text>
                    </Col>
                    <Col span={5}>
                      <Text strong>{t("Initial prompt")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].initial_prompt}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={5}>
                      <Text strong>{t("Max initial timestamp")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].max_initial_timestamp}</Text>
                    </Col>
                    <Col span={5}>
                      <Text strong>{t("No speech threshold")}:</Text>
                    </Col>
                    <Col span={7}>
                      <Text>{transcriptionsData.transcriptions[currentTranscriptionIndex].no_speech_threshold}</Text>
                    </Col>
                  </Row>
                </>
              }
              
              <div
                id="scrollableDiv"
                style={{
                  maxHeight: '63vh',
                  minHeight: '63vh',
                  overflow: 'auto',
                  padding: '10 10',
                }}
              >
                {/* {(!showButton ? filteredData?.length > 0 : trDataRef?.current?.length > 0) && */}
                  <List
                    size='middle'
                    locale={transcriptionsData?.transcriptions[currentTranscriptionIndex]?.id
                      ? {emptyText: t('Wait for timestamp with transcription')}
                      : {emptyText: t('There is no available transcription')} 
                    }
                    loading={isFetchingTranscriptionsData}
                    dataSource={!showButton ? filteredData : trDataRef?.current}
                    renderItem={(item, index) => (
                    <List.Item ref={titleRef} className={item?.className} actions={[
                        // <Checkbox 
                        //   autoFocus={false}
                        //   onChange={(e) => {
                        //     titleRef.current.focus();
                        //   }}
                        //   onKeyDown={(e) => {
                        //     e.preventDefault();
                        //   }}
                        //   >
                        //     Accept
                        //   </Checkbox>, 
                        // <DislikeOutlined/>
                        ]}>
                      <List.Item.Meta
                        avatar={<Avatar style={{color: speakerColor[typeof item?.speaker === "string" ? item?.speaker : "111"]}} /*icon={<UserOutlined/>}*/>{typeof item?.speaker === "string" ? item?.speaker : "-"}</Avatar>}
                        description={<Button style={{color: speakerColor[typeof item?.speaker === "string" ? item?.speaker : "111"]}} type='link' onClick={() => {audioPlayerRef.current.goTo(item.start)}}>{item?.timecode}</Button>}
                      />
                      {item.text}
                    </List.Item>
                  )}
                  />
                    {/* } */}
              </div>
            </Col>
            <Col span={12}>
            <Row>
            <Col span={24}>
            <Divider style={{margin:'2px', paddingTop:'24px'}}>
                <Button 
                  onClick={() => setCallDetailsVisible(!callDetailsVisible)}
                  style={{fontSize:'12px'}} 
                  icon={callDetailsVisible ? <UpOutlined /> : <DownOutlined />}
                  type='link'>
                    {`${callDetailsVisible ? t('Hide') : t('Show')} ${t("Call details")}`}
                </Button>
              </Divider>
              {/* <Title level={4} >{t("Call details")}</Title> */}
              {callDetailsVisible &&
              <>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Project")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.project}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Project ID")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.projid}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Pilot")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.pilot}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Call date")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.call_date}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Phone")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.phone}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Agent CCA")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.agdir}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Agent name")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.agname}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Duration")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{secondsToTimeFormat(call_data?.duration)}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("IVR time")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{secondsToTimeFormat(call_data?.ivr_time)}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Wait time")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{secondsToTimeFormat(call_data?.wait_time)}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Talk")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{secondsToTimeFormat(call_data?.talk_time)}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Hold")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{secondsToTimeFormat(call_data?.hold_time)}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Call theme")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.call_theme}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Call sub theme")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.call_sub_theme}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("Mars ID")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.marsid}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("ID 1")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.id1}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("ID 2")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.id2}</Text>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Text strong>{t("End cause")}:</Text>
                </Col>
                <Col span={18}>
                  <Text>{call_data?.end_cause?.index}: {call_data?.end_cause?.description}</Text>
                </Col>
              </Row>
            </>
            }
            </Col>
            </Row>
            <Row>
              
            <div
                id="scrollableDiv"
                style={{
                  maxHeight: '63vh',
                  minHeight: '63vh',
                  overflow: 'auto',
                  padding: '26 10',
                }}
              >
                <Title level={4} >{t("QA")}</Title>

                <List
                    size='middle'
                    // locale={qaChatData?.count === 0
                    //   ? {emptyText: t('Wait for timestamp with transcription')}
                    //   : {emptyText: t('There is no available transcription')} 
                    // }
                    loading={isFetchingQaChatData}
                    dataSource={qaChatData?.answers || []}
                    renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        // avatar={<Avatar style={{color: speakerColor[typeof item?.speaker === "string" ? item?.speaker : "111"]}} /*icon={<UserOutlined/>}*/>{typeof item?.speaker === "string" ? item?.speaker : "-"}</Avatar>}
                        // description={<Button style={{color: speakerColor[typeof item?.speaker === "string" ? item?.speaker : "111"]}} type='link' onClick={() => {audioPlayerRef.current.goTo(item.start)}}>{item?.timecode}</Button>}
                        description={item.question}
                      />
                      {item.answer}
                    </List.Item>
                  )}
                  />

            </div>
            </Row>
            </Col>
          </Row>
            
          
          </>
      </Modal>
    </>
  )
}

export default AudioPage;
