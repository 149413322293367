// landing-page.jsx
import React, { useRef, useCallback } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone'

// import {
//   ChakraProvider,
//   Box,
//   Flex,
//   Heading,
//   Text,
//   Button,
//   VStack,
//   HStack,
//   Icon,
//   StackProps,
//   Stack,
//   useColorModeValue,
//   Link,
//   LinkOverlay,
//   Image
// } from '@chakra-ui/react';
// import { Fade, ScaleFade, Slide, SlideFade, Collapse } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa';
import "./LandingPage.css";
import { BO } from 'country-flag-icons/react/1x1';
import LandingSpeechToText from './LandingSpeechToText';


const LandingPage = () => {
  const featureRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const scrollToSection = (index) => {
    featureRefs[index].current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <></>
    // <ChakraProvider>
    //   <Box bg={useColorModeValue('gray.50', 'gray.800')} minH="100vh">
    //     <Header scrollToSection={scrollToSection} />
    //     <HeroSection scrollToSection={scrollToSection} heroRef={featureRefs}/>
    //     <FeaturesSection scrollToSection={scrollToSection} featureRefs={featureRefs} />
    //     <Footer />
    //   </Box>
    // </ChakraProvider>
  );
};

const Header = ({ scrollToSection }) => {
  return (
    <></>
    // <Flex
    //   as="header"
    //   align="center"
    //   justify="space-between"
    //   wrap="wrap"
    //   paddingTop={2}
    //   paddingBottom={2}
    //   paddingLeft={12}
    //   paddingRight={12}
    //   // position="sticky"
    //   position="fixed"
    //   top="0"
    //   // zIndex="10"
    //   height={{ base: 'auto', md: '64px' }}
    //   backgroundColor="rgba(255, 255, 255, 0.8)" 
    //   border='1px' borderColor='gray.200'
    //   backdropFilter="saturate(180%) blur(5px)" 
    //   w="100%"
    //   zIndex={3}
    // >
    //   <Flex align="center" mr={5}>
    //     {/* <Image src="./adelina_favicon-150x150.png" alt="Logo" boxSize="40px" /> */}
    //     <Link onClick={() => scrollToSection(0)}><Image src="https://www.adelinacallcenter.com/wp-content/uploads/2022/07/adelina_new.png" alt="Logo" style={{maxWidth: "120px"}} /></Link>
    //     {/* <Heading as="h1" size="lg" letterSpacing={'tighter'}>
    //       Adelina
    //     </Heading> */}
    //   </Flex>
    //   <HStack spacing={4}>
    //     <Link onClick={() => scrollToSection(0)}>Home</Link>
    //     <Link onClick={() => scrollToSection(1)}>Speech-to-text</Link>
    //     <Link onClick={() => scrollToSection(2)}>Text-to-speech</Link>
    //     <Link onClick={() => scrollToSection(3)}>Quality analisys</Link>
    //   </HStack>
    // </Flex>
  );
};

const HeroSection = ({ scrollToSection, heroRef }) => {
  return (
    <></>
    // <Box
    //   textAlign="center"
    //   py={20}
    //   px={6}
    //   height={{ base: 'auto', md: '94vh' }}
    //   ref={heroRef[0]}
    // >
    //   <Heading
    //     as="h1"
    //     fontSize="6xl"
    //     fontWeight="bold"
    //     color={useColorModeValue('gray.700', 'white')}
    //   >
    //     Transform Your Call Center with AI Solutions
    //   </Heading>
    //   <Text fontSize="xl" mt={4} color={useColorModeValue('gray.600', 'gray.400')}>
    //     Our AI-powered solutions bring cutting-edge technology to your call center operations
    //   </Text>
    //   {/* <Button
    //     rightIcon={<Icon as={FaArrowRight} />}
    //     colorScheme="blue"
    //     size="lg"
    //     mt={8}
    //     onClick={() => scrollToSection(1)}
    //   >
    //     Get Started
    //   </Button> */}
    //   <Box p={6} >
    //   <Heading textAlign="center" fontSize="3xl" mb={10}>
    //     Revolutionize Customer Interactions
    //   </Heading>
    //   <Stack
    //     direction={{ base: 'column', md: 'row' }}
    //     spacing={8}
    //     justify="center"
    //   >
    //     <Box cursor="pointer" onClick={() => scrollToSection(1)}>
    //       <Feature
    //         imgSrc="./stt.webp"
    //         title="Speech-to-Text"
    //         text="Accurately transcribe calls and distinguish between different speakers for clear, actionable insights."
    //         borderRadius="md"
    //         maxW="sm"
    //         zIndex={1}
    //         _hover={{
    //           boxShadow: "lg",
    //           transform: 'translateY(-2px)',
    //           transitionDuration: '0.3s',
    //           transitionTimingFunction: "ease-in-out"
    //         }}

    //       />
    //     </Box>
    //     <Box cursor="pointer" onClick={() => scrollToSection(2)}>
    //     <Feature
    //       imgSrc="./tts.webp"
    //       title="Text-to-Speech"
    //       text="Deliver personalized and natural-sounding responses using our advanced text-to-speech technology tailored to your business needs."
    //       borderRadius="md"
    //       maxW="sm"
    //       _hover={{
    //         boxShadow: "lg",
    //         transform: 'translateY(-2px)',
    //         transitionDuration: '0.3s',
    //         transitionTimingFunction: "ease-in-out"
    //       }}
    //     />
    //     </Box>
    //     <Box cursor="pointer" onClick={() => scrollToSection(3)}>
    //     <Feature
    //       imgSrc="./qa.webp"
    //       title="Quality Analysis"
    //       text="Leverage large language models to analyze and enhance the quality of your transcribed calls, ensuring top-notch customer service."
    //       borderRadius="md"
    //       maxW="sm"
    //       _hover={{
    //         boxShadow: "lg",
    //         transform: 'translateY(-2px)',
    //         transitionDuration: '0.3s',
    //         transitionTimingFunction: "ease-in-out"
    //       }}
    //     />
    //     </Box>
    //   </Stack>
    // </Box>
    // </Box>
  );
};

const Feature = React.forwardRef((props, ref) => {
  const { title, text, imgSrc, ...rest } = props;
  return (
    <></>
    // <Box p={5} shadow="md" borderWidth="1px" ref={ref} {...rest} >
    //   <Heading fontSize="xl">{title}</Heading>
    //   <Text mt={4}>{text}</Text>
    //   <Image mt={4} src={imgSrc} alt="tts" boxSize="100%" />
    // </Box>
  );
});

const FeaturesSection = ({ scrollToSection, featureRefs }) => {
  return (
    <></>
    // <Box>
    //   <Box
    //     minH="94vh"
    //     display="flex"
    //     flexDirection="column"
    //     justifyContent="center"
    //     ref={featureRefs[1]}
    //   >
    //     <LandingSpeechToText />
    //   </Box>
        
    //   <Box
    //     minH="94vh"
    //     display="flex"
    //     flexDirection="column"
    //     justifyContent="center"
    //     ref={featureRefs[2]}
    //   >
    //     <Heading textAlign="center" fontSize="3xl" mb={10}>
    //     Deliver personalized and natural-sounding responses using our advanced text-to-speech technology tailored to your business needs.
    //     </Heading>
    //   </Box>
    //   <Box
    //     minH="94vh"
    //     display="flex"
    //     flexDirection="column"
    //     justifyContent="center"
    //     ref={featureRefs[3]}
    //   >
    //     <Heading textAlign="center" fontSize="3xl" mb={10}>
    //     Leverage large language models to analyze and enhance the quality of your transcribed calls, ensuring top-notch customer service.
    //     </Heading>
    //   </Box>
    // </Box>
  );
};

const Footer = () => {
  return (
    <></>
    // <Box
    //   textAlign="center"
    //   p={6}
    //   // bg={useColorModeValue('white', 'gray.700')}
    //   color={useColorModeValue('gray.600', 'gray.200')}
    // >
    //   <Text>© 2023 MyApp. All rights reserved.</Text>
    // </Box>
  );
};

export default LandingPage;