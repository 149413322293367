import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Layout, Button, Typography, Row, Col, DatePicker, Table, Slider, message, Divider, Select, Tooltip, Input, InputNumber, Space } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { SearchOutlined, PlaySquareOutlined, UpOutlined, DownOutlined, FileTextOutlined, PhoneOutlined, NumberOutlined, FieldStringOutlined, SoundOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import AudioPage from './AudioPage';
import { AudioPlayer } from '../AudioPlayer';
import { GetPresetRanges } from './CalendarPresets';
import { secondsToTimeFormat } from '../../utils/helpers/dateTime';
import { 
  useGetAudioRecordsQuery, 
  useGetAudioRecordTranscriptionQuery, 
  useGetProjectsQuery, 
  useGetPilotsQuery,
  useGetAccessCodesQuery,
  useGetAgentsQuery,
} from '../../utils/api/audioApi';
import './TranscriptionViewer.css'


dayjs.extend(utc);
const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;


const TranscriptionViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projid:pid } = useParams();
  const [currentMarsId, setCurrentMarsId] = useState(null)
  const [marsId, setMarsId] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState([dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(0, 'day').endOf('day')]); //[dayjs().startOf('day'), dayjs().endOf('day')]
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [projectId, setProjectId] = useState(location.state?.projectId);
  const [pilot, setPilot] = useState(null);
  const [accessCode, setAccessCode] = useState(null);
  const [agent, setAgent] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [haveTranscription, setHaveTranscription] = useState(true);
  const [sortField, setSortField] = useState('call_date');
  const [sortOrder, setSortOrder] = useState('-');
  const [additionalFiltersVisible, setAdditionalFiltersVisible] = useState(false);


  const [isFiltered, setIsFiltered] = useState(false);

  // useEffect(() => {
  //   // const hash = window.location.hash.slice(1);
  //   // setProjectId(hash);
  //   // setSearchParams(searchParamsValues);
  //   const path = location.pathname;
  //   const newPath = path.substring(0, path.lastIndexOf('/')); // Removes the last segment of the URL
  //   navigate(newPath);
  // }, [])

  const columns = [
    { title: t('Transcription'), dataIndex: 'marsid', width: 14,
      render: (currentMarsId, item) => {
                return (
                  <Row justify={"center"}>
                    <Button 
                      style={{width:"80%"}} 
                      // icon={<><PlaySquareOutlined/><Divider type='vertical' /><FileTextOutlined/></>} 
                      icon={<><SoundOutlined /><Divider type='vertical' /><FieldStringOutlined/></>} 
                      // icon={<><FieldStringOutlined /></>} 
                      type={item?.have_transcription ? "primary" : "default"}
                      onClick={()=>{onCardClick(currentMarsId)}}
                      />
                  </Row>
                )
              } },
    { title: t('Mars ID'), dataIndex: 'marsid', render: (text) => (<a onClick={() => handleCopy(text)}>{text}</a>), sorter: true},
    { title: t('Project ID'), dataIndex: 'projid', sorter: true},
    { title: t('Project'), dataIndex: 'project', sorter: true},
    { title: t('Pilot'), dataIndex: 'pilot', sorter: true},
    { title: t('Access code'), dataIndex: 'access_code', sorter: true},
    { title: t('Call date'), dataIndex: 'call_date', render: (date) => dayjs.utc(date).format('YYYY-MM-DD  HH:mm:ss'), sorter: true}, //sorter: (a, b) => dayjs(a.call_date).unix() - dayjs(b.call_date).unix(),},
    { title: t('Phone'), dataIndex: 'phone', render: (text) => (<a onClick={() => handleCopy(text)}>{text}</a>), sorter: true},
    { title: t('CCA'), dataIndex: 'agdir', sorter: true},
    { title: t('Agent'), dataIndex: 'agname', sorter: true},
    { title: t('Duration'), dataIndex: 'duration', render: (text) => <Tooltip title={`${text} ${t('s')}`}>{secondsToTimeFormat(text)}</Tooltip>, sorter: true },
    { title: t('Talk'), dataIndex: 'talk_time', render: (text) => <Tooltip title={`${text} ${t('s')}`}>{secondsToTimeFormat(text)}</Tooltip>, sorter: true },
    { title: t('Hold'), dataIndex: 'hold_time', render: (text) => <Tooltip title={`${text} ${t('s')}`}>{secondsToTimeFormat(text)}</Tooltip>, sorter: true },

  ];
  const searchParamsValues = {
        call_date_start:
            dateRange && dateRange?.length && marsId === null
                ? dayjs(dateRange[0]?.toISOString()).format("YYYY-MM-DD 00:00:00")
                : null,
        call_date_end:
            dateRange && dateRange?.length && marsId === null
                ? dayjs(dateRange[1]?.toISOString()).format("YYYY-MM-DD 23:59:59")
                : null,
        phone: phoneNumber,
        pilot: pilot,
        access_code: accessCode,
        agent: agent,
        projid: projectId,
        record_id: marsId,
        have_transcription: haveTranscription,
        offset: (pageNum-1)*pageSize,
        limit: pageSize,
        order_by: `${sortOrder}${sortField}`,
  }

  const [searchParams, setSearchParams] = useState(searchParamsValues);

  const { data, isFetching: isFetchingRecords, error, refetch, isSuccess } = useGetAudioRecordsQuery(
    searchParams
  );
  
  const { data: projects, isFetching: isFetchingProjects, isSuccess: isSuccessProjects, refetch: refetchProjects } = useGetProjectsQuery();
  const { data: pilots, isFetching: isFetchingPilots, isSuccess: isSuccessPilots, refetch: refetchPilots } = useGetPilotsQuery({projid: projectId});
  const { data: accessCodes, isFetching: isFetchingaccessCodes, isSuccess: isSuccessaccessCodes, refetch: refetchaccessCodes } = useGetAccessCodesQuery({projid: projectId});
  const { data: agents, isFetching: isFetchingAgents, isSuccess: isSuccessAgents, refetch: refetchAgents } = useGetAgentsQuery({projid: projectId});
  

  const handleCopy = (text) => {
    copy(text);
    message.success(t('Copied to clipboard'));
  }

  const onCardClick = (marsid) => {
    setCurrentMarsId(marsid);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setCurrentMarsId(null);
    setIsModalVisible(false);
  };

  const handlePageNumChange = (newPageNum, newPageSize) => {
    if(newPageSize !== pageSize) {
        newPageNum = 1;
        setPageSize(newPageSize);
    };
    setPageNum(newPageNum);
  };

  const handleSearch = () => {
    setPageNum(1);
    setSearchParams(searchParamsValues);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    if(extra.action === 'sort'){
      setPageNum(1);
      setSortField(sorter.field);
      setSortOrder(sorter.order === 'ascend' ? '' : '-');
      setSearchParams(searchParamsValues);
    }
  };

  useEffect(() => {
    setSearchParams(prevParams => ({
      ...prevParams,
      offset: (pageNum - 1) * pageSize,
      limit: pageSize,
      order_by: `${sortOrder}${sortField}`,
    }));
  }, [pageNum, pageSize, sortOrder, sortField]);

  useEffect(() => {
    if(marsId){
      setDateRange([null, null]);
    }
  }, [marsId]);


  return (
    <>
        <Row gutter={24} className="content-filter-row">
          <Space direction='horizontal'>
            <DatePicker.RangePicker 
              value={dateRange}
              presets={GetPresetRanges()}
              onCalendarChange={(val) => setDateRange(val)}
              style={{ minWidth: 280 }}
            />
            <Select 
              allowClear
              showSearch
              filterOption={(input, option) => { return ((`${option?.value} ${option?.label}`) ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}}
              value={projectId}
              onChange={(a,e)=>{setProjectId(e?.value)}}
              placeholder={t("Project")}
              options={projects?.map((p) => ({ value: p?.projid, label: `${p?.projid}: ${p?.project}` }))}
              
              loading={isFetchingProjects}
              style={{ minWidth: 200 }}
            />
            <Select 
              allowClear
              showSearch
              filterOption={(input, option) => {return (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}}
              value={pilot}
              onChange={(a,e)=>{setPilot(e?.value)}}
              placeholder={t("Pilot")}
              // options={pilots?.map((p) => ({ value: p, label: p }))}
              options={pilots?.map((p) => ({ value: p.pilot, label: `${p.pilot}: ${p.name}` }))}
              loading={isFetchingPilots}
              style={{ minWidth: 230 }}
            />
            
            <Select 
              allowClear
              showSearch
              filterOption={(input, option) => {return (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}}
              value={agent}
              onChange={(a,e)=>{setAgent(e?.value)}}
              placeholder={t("Agent")}
              options={agents?.map((p) => ({ value: p?.agdir, label: p?.agname }))}
              loading={isFetchingAgents}
              style={{ minWidth: 200 }}
            />
            <Input
              prefix={<NumberOutlined />} 
              value={marsId} 
              onChange={(e)=>{setMarsId(e.target.value)}} 
              placeholder={t("Mars ID")} 
              allowClear={true}
              style={{ minWidth: 140 }}
              onPressEnter={handleSearch}
            />
            <Button 
              type="primary" 
              onClick={handleSearch} 
              icon={<SearchOutlined />}
              style={{ minWidth: 40 }}
              >
                {t("Search")}
            </Button>
          </Space>
        </Row>
        {additionalFiltersVisible && 
        <Row gutter={24} className="content-filter-row">
          <Space direction='horizontal'>
            <Input
              prefix={<PhoneOutlined />} 
              value={phoneNumber} 
              onChange={(e)=>{setPhoneNumber(e.target.value)}} 
              placeholder={t("Phone number")} 
              allowClear={true}
              style={{ minWidth: 140 }}
              onPressEnter={handleSearch}
            />
            <Select 
              allowClear
              showSearch
              filterOption={(input, option) => {return (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())}}
              value={accessCode}
              onChange={(a,e)=>{setAccessCode(e?.value)}}
              placeholder={t("Access code")}
              options={accessCodes?.map((p) => ({ value: p, label: p }))}
              loading={isFetchingaccessCodes}
              style={{ minWidth: 140 }}
            />
            <Select
              value={haveTranscription} 
              onChange={(v)=>{setHaveTranscription(v)}}
              placeholder={t("With transcription")} 
              options={[{value: true, label: t("Yes")}, {value: false, label: t("No")}]}
              allowClear={true}
              style={{ minWidth: 140 }}
            />
          </Space>
        </Row>
      }
        <Row gutter={24} className="content-filter-row content-filter-show-button-row">
          <Button
              className='content-filter-show-button'
              type="ghost"
              size='small'
              onClick={() => setAdditionalFiltersVisible(!additionalFiltersVisible)}
              
            >
              {additionalFiltersVisible ? <><UpOutlined /> {t("Hide additional filters")} <UpOutlined /></> : <><DownOutlined /> {t("Show additional filters")} <DownOutlined /></>}
            </Button>
        </Row>
        <Row gutter={24}  className="content-row">
          <Col span={24} className="content-row-column">
              <Table 
              size="small"
              dataSource={data?.calls}
              columns={columns}
              pagination={{
                  position: ["none", "bottomCenter"],
                  onChange: handlePageNumChange,
                  current: pageNum,
                  pageSizeOptions: [10, 20, 50, 100],
                  defaultPageSize: 10,
                  total: data?.count,
              }}
              onChange={onTableChange}
              loading={isFetchingRecords}
              sortOrder={sortOrder === '-' ? 'descend' : 'ascend'}
              sortField={sortField}
              showSorterTooltip={false}
              rowClassName={(record, index) => {'rrrrrr'}}
              />
          </Col>
        </Row>
        {currentMarsId &&
            <AudioPage
                call_data={data?.calls?.find((c) => c?.marsid === currentMarsId)}
                marsid={currentMarsId}
                visible={isModalVisible}
                onCancel={handleModalClose}
                key={currentMarsId}
            />
        }
    </>
  );
};

export default TranscriptionViewer;
