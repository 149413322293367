import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { loadState } from '../localStorage';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL;


class WebSocketManager {
    constructor() {
      this.handlers = [];
      this.ws = null;
      this.reconnectDelay = 5000;
      this.reconnectAttempts = 0;
      this.maxReconnectAttempts = 5;
      this.url = null;
    }
  
    connect(url) {
      if (this.ws && this.url === url) {
        return;
      }
      this.url = url;
      this.close();
      this.ws = new WebSocket(this.url);
      this.ws.onopen = () => {
        this.reconnectAttempts = 0; 
      };
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.handlers.forEach(handler => handler(data));
      };
      this.ws.onclose = (event) => {
        // console.log("onclose", event);
        if (event.code === 1000) {
            return;  // Exit the handler early, no reconnection attempt
        }
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
          this.reconnectAttempts++;
          setTimeout(() => this.reconnect(), this.reconnectDelay * Math.pow(2, this.reconnectAttempts));
        }
      };
      this.ws.onerror = (error) => {
        // console.log("onerror", error);
        this.ws.close();
      };
    }
  
    reconnect() {
      if (this.url) {
        this.close();
        this.connect(this.url);
      }
    }
  
    registerHandler(handler) {
      this.handlers.push(handler);
    }
  
    unregisterHandler(handler) {
      this.handlers = this.handlers.filter(h => h !== handler);
    }
  
    sendMessage(message) {
      this.ws?.send(JSON.stringify(message));
    }
  
    close() {
      this.ws?.close();
      this.ws = null;
    }
  }

const channelsWsManager = new WebSocketManager();
const suggestionsWsManager = new WebSocketManager();
const bridgesWsManager = new WebSocketManager();

function transcriptionMessageHandler(data, updateCachedData) {
    // console.log("transcriptionMessageHandler", data);
    if (data?.type === 'embeddings.copilot.response') {
        updateCachedData((draft) => {
            draft.context_list = data?.context_list;
            draft.transcription = data?.transcription;
        });
    }
}

function bridgeConversationHandler(data, updateCachedData) {
  // console.log("bridgeConversationHandler", data);
  
  if (data?.type === 'final.result.bridge') {
      updateCachedData((draft) => {
          draft.conversation.push(data);
      });
  }
  
  if (data?.type === 'bridges.copilot.response') {
      updateCachedData((draft) => {
          draft.conversation.forEach(message => {
              if (message.message_id === data.message_id) {
                  message.copilot_response = data;
                  // console.log("Copilot response", message);
              }
          });
      });
  }
}

function channelsHandler(data, updateCachedData) {
    updateCachedData((draft) => {
        draft.channels = data?.channels; 
    });
}

function bridgesHandler(data, updateCachedData) {
  updateCachedData((draft) => {
    // console.log("bridgesHandler", data);

    if (data?.type === 'get.bridges') {
      draft.bridges = data?.bridges;
    }

    if (data?.type === 'bridge.created') {
      const existingBridge = draft.bridges.find(bridge => bridge.bridge_id === data.bridge_id);
      if (!existingBridge) {
        draft.bridges.push({ bridge_id: data.bridge_id, channels: [] });
      }
    }

    if (data?.type === 'bridge.destroyed') {
      draft.bridges = draft?.bridges?.filter(bridge => bridge?.bridge_id !== data?.bridge_id);
    }

    if (data?.type === 'call.created') {
      const bridge = draft?.bridges?.find(bridge => bridge?.bridge_id === data?.bridge_id);
      if (bridge) {
        const existingChannel = bridge.channels.find(channel => channel.channel_id === data?.channel_id);
        if (!existingChannel) {
          bridge.channels.push({ channel_id: data?.channel_id, caller_number: data?.caller_number, status: 'dialing', status_color: 'orange' });
        }
      }
    }

    if (data?.type === 'call.bridged') {
      const bridge = draft?.bridges?.find(bridge => bridge?.bridge_id === data?.bridge_id);
      if (bridge) {
        const channel = bridge.channels.find(channel => channel.channel_id === data?.channel_id);
        if (channel) {
          channel.status = 'connected';
          channel.status_color = 'green';
        }
      }
    }

    if (data?.type === 'call.destroyed') {
      const bridge = draft?.bridges?.find(bridge => bridge?.bridge_id === data?.bridge_id);
      if (bridge) {
        bridge.channels = bridge.channels.filter(channel => channel.channel_id !== data?.channel_id);
      }
    }
  });
}

export const asteriskApi = createApi({
    reducerPath: 'asteriskApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/stt/`,
        credentials: "include",
        prepareHeaders: (headers) => {
          headers.set('Authorization', `Bearer ${loadState('auth').access}`);
          return headers;
        }
    }),
    endpoints: (build) => ({
        getSuggestions: build.query({
            query: (caller_number) => ({
                url: `ast_suggestions/${caller_number}`,
                method: 'GET',
            }),
            async onCacheEntryAdded(caller_number, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const handler = (data) => transcriptionMessageHandler(data, updateCachedData);
                suggestionsWsManager.connect(`${BASE_WS_URL}/ast_suggestions/${caller_number}`);
                suggestionsWsManager.registerHandler(handler);
                try {
                    await cacheDataLoaded;
                } catch {
                    suggestionsWsManager.unregisterHandler(handler);
                }
                await cacheEntryRemoved;
                suggestionsWsManager.unregisterHandler(handler);
            },
        }),
        getBridgeSuggestions: build.query({
            query: (caller_number) => ({
                url: `ast_bridge_suggestions/${caller_number}`,
                method: 'GET',
            }),
            async onCacheEntryAdded(caller_number, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const handler = (data) => transcriptionMessageHandler(data, updateCachedData);
                suggestionsWsManager.connect(`${BASE_WS_URL}/ast_bridge_suggestions/${caller_number}`);
                suggestionsWsManager.registerHandler(handler);
                try {
                    await cacheDataLoaded;
                } catch {
                    suggestionsWsManager.unregisterHandler(handler);
                }
                await cacheEntryRemoved;
                suggestionsWsManager.unregisterHandler(handler);
            },
        }),
        
        getBridgeConversation: build.query({
            query: (bridge_id) => ({
                url: `ast_bridge_conversation/${bridge_id}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            async onCacheEntryAdded(bridge_id, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const handler = (data) => bridgeConversationHandler(data, updateCachedData);
                suggestionsWsManager.connect(`${BASE_WS_URL}/ast_bridge_conversation/${bridge_id}`);
                suggestionsWsManager.registerHandler(handler);
                try {
                    await cacheDataLoaded;
                } catch {
                    suggestionsWsManager.unregisterHandler(handler);
                }
                await cacheEntryRemoved;
                suggestionsWsManager.unregisterHandler(handler);
            },
        }),
        
        getChannels: build.query({
            query: () => ({
                url: 'ast_channels_info',
                method: 'GET',
            }),
            async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const handler = (data) => channelsHandler(data, updateCachedData);
                channelsWsManager.connect(`${BASE_WS_URL}/ast_channels_info`);
                channelsWsManager.registerHandler(handler);
                try {
                    await cacheDataLoaded;
                } catch {
                    channelsWsManager.unregisterHandler(handler);
                }
                await cacheEntryRemoved;
                channelsWsManager.unregisterHandler(handler);
            },
        }),
        getBridges: build.query({
            query: () => ({
                url: 'ast_bridges_info',
                method: 'GET',
            }),
            async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const handler = (data) => bridgesHandler(data, updateCachedData);
                bridgesWsManager.connect(`${BASE_WS_URL}/ast_bridges_info`);
                bridgesWsManager.registerHandler(handler);
                try {
                    await cacheDataLoaded;
                } catch {
                    bridgesWsManager.unregisterHandler(handler);
                }
                await cacheEntryRemoved;
                bridgesWsManager.unregisterHandler(handler);
            },
        }),
        makeCall: build.mutation({
            query: (params) => ({
                url: '/call', 
                method: 'POST',
                body: params,
            }),
        }),
    }),
});

export const {
    useGetSuggestionsQuery,
    useGetBridgeSuggestionsQuery,
    useGetBridgeConversationQuery,
    useGetChannelsQuery,
    useGetBridgesQuery,
    useMakeCallMutation,
} = asteriskApi;
